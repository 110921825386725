import React from 'react';

const PlayIcon = ({ opacity = '1', width = '40', height = '40', style }) => (
  <div style={style}>
    <svg
      width={width}
      height={height}
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        opacity={opacity}
        d="M20 0C8.95161 0 0 8.95161 0 20C0 31.0484 8.95161 40 20 40C31.0484 40 40 31.0484 40 20C40 8.95161 31.0484 0 20 0ZM29.3306 21.9355L15.1371 30.0806C13.8629 30.7903 12.2581 29.879 12.2581 28.3871V11.6129C12.2581 10.129 13.8548 9.20968 15.1371 9.91935L29.3306 18.5484C30.6532 19.2903 30.6532 21.2016 29.3306 21.9355Z"
        fill="white"
      />
    </svg>
  </div>
);

export default PlayIcon;
