import React from 'react';
import styled from 'styled-components';

// resources imports
import {
  greyscale04,
  greyscale07,
  h3,
  white
} from '../../../resources/consts/styleGuide';

class TrustedBy extends React.PureComponent {
  render() {
    const { brands } = this.props;

    return (
      <Container>
        <Title>Trusted By</Title>
        <BrandsContainer>
          {brands &&
            brands.length > 0 &&
            brands.map(brand => (
              <BrandContainer key={brand.name}>
                <BrandLogo src={brand.logo} alt={brand.name} />
              </BrandContainer>
            ))}
        </BrandsContainer>
        {brands.length === 0 && <EmptyText>Be the first</EmptyText>}
      </Container>
    );
  }
}

export default TrustedBy;

const Container = styled.div`
  max-width: 1020px;
  min-height: 132px;

  display: flex;
  flex-direction: column;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
`;

const Title = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 20px;
`;

const BrandsContainer = styled.div`
  display: flex;
  flex-direction: grid;
  flex-wrap: wrap;
  justify-content: flex-start;

  column-gap: 40px;
  row-gap: 40px;
`;

const BrandContainer = styled.div`
  width: 106px;
  height: 106px;

  cursor: pointer;
`;

const BrandLogo = styled.img`
  width: 100%;
  height: 100%;
`;

const EmptyText = styled(h3)`
  align-self: center;
  color: ${greyscale04};
`;
