import firebase from 'firebase/app';
import 'firebase/analytics';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

// // DEBUG
const firebaseConfig = {
  apiKey: 'AIzaSyClOioWIDhYBCixCrKkn3dSJGD2Ccjl21k',
  authDomain: 'marketplace-629c5.firebaseapp.com',
  projectId: 'marketplace-629c5',
  storageBucket: 'marketplace-629c5.appspot.com',
  messagingSenderId: '293689089650',
  appId: '1:293689089650:web:a7c55535f68a1c83082ceb',
  measurementId: 'G-0DKKBGMQ83'
};

// RELEASE (Change it!!!)
// const firebaseConfig = {
//   apiKey: "AIzaSyClOioWIDhYBCixCrKkn3dSJGD2Ccjl21k",
//   authDomain: "marketplace-629c5.firebaseapp.com",
//   projectId: "marketplace-629c5",
//   storageBucket: "marketplace-629c5.appspot.com",
//   messagingSenderId: "293689089650",
//   appId: "1:293689089650:web:a7c55535f68a1c83082ceb",
//   measurementId: "G-0DKKBGMQ83"
// };

firebase.initializeApp(firebaseConfig);
firebase.firestore().enablePersistence();
firebase.analytics();
firebase.storage();
export default firebase;
