import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';

// resources imports
import { white } from '../../resources/consts/styleGuide';
import SocialIcon from '../../resources/images/icons/socials/socials_icon';

class Socials extends React.PureComponent {
  render() {
    const { socials } = this.props;
    return (
      <Container>
        {socials &&
          socials.length > 0 &&
          socials.map(social => (
            <SocialIconContainer
              key={social.name}
              href={social.link}
              target="_blank"
              onClick={() => {
                firebase.analytics().logEvent('social_link_outbound_click', {
                  social: social.name
                });
              }}
            >
              <SocialIcon social={social.name} />
            </SocialIconContainer>
          ))}
      </Container>
    );
  }
}

export default Socials;

const Container = styled.div`
  width: 310px;
  height: 60px;

  display: flex;
  flex-direction: row;
  justify-content: center;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 10px 10px 10px 10px;

  column-gap: 15px;
`;

const SocialIconContainer = styled.a`
  :hover {
    opacity: 0.7;
  }
`;
