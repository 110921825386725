import moment from 'moment-timezone';

export function getFormattedTimezone(timezone) {
  const abbr = moment.tz(timezone).format('z');
  const offset = moment.tz(timezone).format('Z');

  const formattedTimezone = `${abbr} (UTC ${offset})`;

  return formattedTimezone;
}
