import React from 'react';
import styled from 'styled-components';

// resources imports
import { greyscale07, h3, white } from '../../../resources/consts/styleGuide';

import Video from '../../global/Video/Video';
import Thumbnail from '../../global/Video/Thumbnail';

class SampleVideos extends React.PureComponent {
  constructor(props) {
    super(props);

    const primaryVideo =
      props.videos && props.videos.length > 0
        ? props.videos.find(video => video.is_primary)
        : { url: '' };

    this.state = { playingVideo: primaryVideo.url };
  }

  updateVideo = video => {
    this.setState({ playingVideo: video.url });
  };

  render() {
    const { videos, image } = this.props;
    const { playingVideo } = this.state;

    return (
      <Container>
        <Title>Sample Videos</Title>
        <Video
          url={playingVideo}
          fallbackImage={image}
          width="960px"
          height="460px"
        />
        <VideosSection>
          {videos &&
            videos.length > 0 &&
            videos.map(video => (
              <Thumbnail
                key={video.url}
                url={video.url}
                fallbackImage={image}
                width="140px"
                height="90px"
                playing={playingVideo === video.url}
                onClick={() => this.updateVideo(video)}
              />
            ))}
        </VideosSection>
      </Container>
    );
  }
}

export default SampleVideos;

const Container = styled.div`
  max-width: 1020px;
  min-height: 132px;

  display: flex;
  flex-direction: column;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
`;

const Title = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 20px;
`;

const VideosSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  margin-top: 20px;

  column-gap: 20px;
`;
