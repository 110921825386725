import React from 'react';
import styled from 'styled-components';
import millify from 'millify';

// resources imports
import {
  greyscale05,
  greyscale07,
  h3,
  normal,
  white
} from '../../../resources/consts/styleGuide';
import SocialIcon from '../../../resources/images/icons/socials/socials_icon';

class Audiences extends React.PureComponent {
  render() {
    const { socials } = this.props;

    return (
      <Container>
        <Title>Audiences</Title>
        <SectionContainer>
          {socials &&
            socials.length > 0 &&
            socials.map(social => (
              <Section key={social.name}>
                <SocialIcon social={social.name} width={60} height={60} />
                <Block>
                  <BlockTitle>
                    {millify(social.primary_stat.value || 0)}
                  </BlockTitle>
                  <BlockValue>
                    <BlockText>{social.primary_stat.name}</BlockText>
                  </BlockValue>
                </Block>
              </Section>
            ))}
        </SectionContainer>
      </Container>
    );
  }
}

export default Audiences;

const Container = styled.div`
  max-width: 1020px;
  min-height: 132px;

  display: flex;
  flex-direction: column;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px 30px;
`;

const Title = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 30px;
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: grid;
  flex-wrap: wrap;
  justify-content: flex-start;

  column-gap: 80px;
  row-gap: 40px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: row;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlockTitle = styled(normal)`
  color: ${greyscale07};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;

  margin-top: 5px; //This is only because social image are not right
`;

const BlockValue = styled.div`
  display: flex;
  flex-direction: row;
`;

const BlockText = styled(normal)`
  color: ${greyscale05};
`;
