import styled, { css } from 'styled-components';

export const h1 = styled.h1`
  font-family: 'Montserrat', sans-serif;
  font-weight: 700;
  font-size: 48px;
  letter-spacing: -0.6px;
  color: #ffffff;
`;

export const h2 = styled.h2`
  font-family: 'Open Sans', sans-serif;
  font-weight: 300;
  font-size: 36px;
  color: #ffffff;
  margin: 10px;
`;

export const h3 = styled.h3`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;

  margin: 0px;
`;

export const normal = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;

  margin: 0px;
`;

export const small = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;

  margin: 0px;
`;

export const span = styled.span`
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;

  margin: 0px;
`;

export const bodyLargeSemiBold = styled.p`
  font-family: 'Open Sans', sans-serif;
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 19px;
  letter-spacing: 0em;
  text-align: left;
`;

export const placeholder = css`
  color: #ffffff;
  opacity: 0.3;
`;

export const red = '#C74141';

export const greyscale10 = '#000000';
export const greyscale09 = '#1A1A1A';
export const greyscale08 = '#333333';
export const greyscale07 = '#4D4D4D';
export const greyscale06 = '#666666';
export const greyscale05 = '#808080';
export const greyscale04 = '#999999';
export const greyscale03 = '#B3B3B3';
export const greyscale02 = '#CCCCCC';
export const greyscale01 = '#E6E6E6';
export const white = '#FFFFFF';

export const orange10 = '#FE8300';
export const orange09 = '#FF901A';
export const orange08 = '#FE8300';
export const orange07 = '#FFA94D';
export const orange06 = '#FEB566';
export const orange05 = '#FFC180';
export const orange04 = '#FFCD99';
export const orange03 = '#FFDAB3';
export const orange02 = '#FFE6CC';
export const orange01 = '#FFF3E6';

export const yellow10 = '#FDB927';
export const yellow09 = '#FEC03D';
export const yellow08 = '#FDC752';
export const yellow07 = '#FECE68';
export const yellow06 = '#FED57D';
export const yellow05 = '#FEDC93';
export const yellow04 = '#FEE3A9';
export const yellow03 = '#FFEABF';
export const yellow02 = '#FFF1D4';
export const yellow01 = '#FFF8EA';

export const aqua10 = '#00AEB3';
export const aqua09 = '#1AB6BB';
export const aqua08 = '#33BEC2';
export const aqua07 = '#4DC6CA';
export const aqua06 = '#66CED1';
export const aqua05 = '#80D7D9';
export const aqua04 = '#99DFE1';
export const aqua03 = '#B3E7E8';
export const aqua02 = '#CCEFF0';
export const aqua01 = '#E5F7F7';

export const magenta10 = '#EB335B';
export const magenta09 = '#ED476B';
export const magenta08 = '#EF5C7C';
export const magenta07 = '#F1708C';
export const magenta06 = '#F3859D';
export const magenta05 = '#F599AD';
export const magenta04 = '#F7ADBD';
export const magenta03 = '#F9C2CE';
export const magenta02 = '#FBD6DE';
export const magenta01 = '#FDEBEF';

export const navy10 = '#162B4C';
export const navy09 = '#2D405E';
export const navy08 = '#455570';
export const navy07 = '#5C6B82';
export const navy06 = '#738094';
export const navy05 = '#8B95A5';
export const navy04 = '#A2AAB7';
export const navy03 = '#B9BFC9';
export const navy02 = '#D0D5DB';
export const navy01 = '#E8EAED';

export const links = css`
  color: ${orange10};
  text-decoration: underline;
`;

export const error = css`
  font-style: italic;
`;
