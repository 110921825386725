import React, { useState } from 'react';

// utilities import
import { generateId } from '../../../utilities/generator';

const Videos = ({ onChange, nextVideos }) => {
  const [videos, setVideo] = useState(nextVideos);

  return (
    <div>
      <button
        style={{ marginBottom: '20px' }}
        type="button"
        onClick={() => {
          setVideo(currentVideos => [
            ...currentVideos,
            {
              id: generateId(),
              url: '',
              is_primary: false
            }
          ]);
        }}
      >
        Add new Video
      </button>
      {videos.map(video => (
        <div style={{ marginBottom: '20px' }} key={video.id}>
          <label htmlFor="url" style={{ marginRight: '20px' }}>
            Video URL:
            <input
              id="url"
              onChange={e => {
                const url = e.target.value;
                setVideo(currentVideos =>
                  currentVideos.map(currentVideo =>
                    currentVideo.id === video.id
                      ? {
                          ...currentVideo,
                          url
                        }
                      : currentVideo
                  )
                );
              }}
              value={video.url}
              placeholder="url"
            />
          </label>
          <label htmlFor="isPrimary" style={{ marginRight: '20px' }}>
            Is Primary:
            <input
              id="isPrimary"
              type="checkbox"
              onChange={e => {
                const isPrimary = e.target.checked;

                setVideo(currentVideos =>
                  currentVideos.map(currentVideo =>
                    currentVideo.id === video.id
                      ? {
                          ...currentVideo,
                          is_primary: isPrimary
                        }
                      : currentVideo
                  )
                );
              }}
              checked={video.is_primary}
            />
          </label>
          <button
            style={{ marginRight: '20px' }}
            type="button"
            onClick={() => {
              onChange(videos);
            }}
          >
            Update Video
          </button>
          <button
            type="button"
            onClick={() => {
              setVideo(currentVideos =>
                currentVideos.filter(
                  currentVideo => currentVideo.id !== video.id
                )
              );
            }}
          >
            Remove Video
          </button>
        </div>
      ))}
    </div>
  );
};

export default Videos;
