import React from 'react';
import styled from 'styled-components';

// resources imports
import {
  greyscale05,
  greyscale07,
  h3,
  normal,
  white
} from '../../resources/consts/styleGuide';

class HostPricing extends React.PureComponent {
  render() {
    const { pricing } = this.props;

    return (
      <Container>
        <Title>Pricing</Title>
        {pricing && pricing.length > 0 && (
          <Price>{`Starting From $${pricing[0].flat_fee} + ${pricing[0]
            .commission * 100}% commission`}</Price>
        )}
        <Disclosure>
          Potential add-ons available, contact host for more information.
        </Disclosure>
      </Container>
    );
  }
}

export default HostPricing;

const Container = styled.div`
  width: 310px;
  min-height: 120px;

  display: flex;
  flex-direction: column;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px;
`;

const Title = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 10px;
`;

const Price = styled(normal)`
  color: ${greyscale05};

  font-size: 14px;
  line-height: 16px;

  margin-bottom: 5px;
`;

const Disclosure = styled(normal)`
  color: ${greyscale05};

  font-weight: normal;
  font-size: 12px;
  line-height: 14px;
`;
