const initState = {};

const brandReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_BRAND': {
      return { ...state, action };
    }
    default:
      return state;
  }
};

export default brandReducer;
