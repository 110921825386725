import React from 'react';
import styled from 'styled-components';

// redux imports
import { connect } from 'react-redux';
import { SignOut } from '../../redux/actions/auth';

// resources imports
import {
  greyscale05,
  greyscale01,
  normal,
  white
} from '../../resources/consts/styleGuide';
// import userSettingsIcon from '../../resources/images/header/user_settings.svg';
import DashboardIcon from '../../resources/images/header/livescale_favicon.svg';
import LogoutIcon from '../../resources/images/header/logout.svg';

class HeaderModal extends React.PureComponent {
  render() {
    const { signOut, onClose } = this.props;

    return (
      <>
        <Scrim onClick={onClose} />
        <Container>
          {/* <LinkButton>
            <LinkIcon src={userSettingsIcon} />
            <LinkText>Account Settings</LinkText>
          </LinkButton> */}
          {/* <Line /> */}
          <ExternalLinkButton
            href="https://console.livescale.tv"
            target="_blank"
          >
            <LinkIcon src={DashboardIcon} />
            <LinkText>Dashboard</LinkText>
          </ExternalLinkButton>
          <Line />
          <LinkButton
            onClick={() => {
              onClose();
              signOut();
            }}
          >
            <LinkIcon src={LogoutIcon} />
            <LinkText>Logout</LinkText>
          </LinkButton>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

const mapDispatchToProps = dispatch => ({
  signOut: () => dispatch(SignOut())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderModal);

const Scrim = styled.div`
  background-color: transparent;
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999998;
`;

const Container = styled.div`
  width: 130px;
  height: 75px;
  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  position: absolute;
  right: 42px;
  top: 80px;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  box-sizing: border-box;
  padding-left: 5px;
  padding-right: 5px;

  overflow: hidden;
  z-index: 999999;

  :focus {
    outline: none;
  }
`;

const Line = styled.div`
  width: 100%;
  height: 1px;

  background-color: ${greyscale01};
`;

const LinkButton = styled.button`
  width: 100%;

  background-color: transparent;

  border: none;
  padding: 0px;

  padding-left: 8px;
  padding-right: 8px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;
`;

const ExternalLinkButton = styled.a`
  width: 100%;

  background-color: transparent;

  padding: 0px;
  box-sizing: border-box;

  padding-left: 8px;
  padding-right: 8px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  cursor: pointer;

  text-decoration: none;
`;

const LinkIcon = styled.img`
  width: 12px;
  height: 12px;
`;

const LinkText = styled(normal)`
  color: ${greyscale05};

  margin: 0px;
  margin-left: 10px;
`;
