import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import { firebaseReducer } from 'react-redux-firebase';
import { firestoreReducer } from 'redux-firestore';

import authReducer from './auth';
import userReducer from './user';
import hostsReducer from './hosts';
import hostReducer from './host';
import brandReducer from './brand';

const rootReducer = history =>
  combineReducers({
    router: connectRouter(history),
    firebase: firebaseReducer,
    firestore: firestoreReducer,
    auth: authReducer,
    user: userReducer,
    hosts: hostsReducer,
    host: hostReducer,
    brand: brandReducer
  });

export default rootReducer;
