import React from 'react';
import styled from 'styled-components';

// resources imports
import {
  aqua07,
  aqua10,
  normal,
  white
} from '../../resources/consts/styleGuide';
import touchIcon from '../../resources/images/icons/get_in_touch_icon.svg';

// components imports
import GetInTouchModal from '../modal/GetInTouchModal';

class GetInTouch extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      showGetInTouch: false
    };
  }

  showModal = () => {
    this.setState({ showGetInTouch: true });
  };

  hideModal = () => {
    this.setState({ showGetInTouch: false });
  };

  render() {
    const { hostName, hostId } = this.props;
    const { showGetInTouch } = this.state;

    return (
      <>
        <Container
          onClick={event => {
            event.stopPropagation();
            this.showModal();
          }}
        >
          <Icon src={touchIcon} /> <Text>Get In Touch</Text>
        </Container>
        {showGetInTouch && (
          <GetInTouchModal
            onClose={this.hideModal}
            hostName={hostName}
            hostId={hostId}
          />
        )}
      </>
    );
  }
}

export default GetInTouch;

const Container = styled.button`
  width: 310px;
  height: 46px;

  display: flex;
  justify-content: center;
  align-items: center;

  column-gap: 10px;

  border-radius: 5px;

  cursor: pointer;

  background-color: ${aqua10};
  outline: none;
  border: 0px;
  padding: 0px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);

  :hover {
    background-color: ${aqua07};
  }
`;

const Icon = styled.img`
  width: 16px;
  height: 16px;
`;

const Text = styled(normal)``;
