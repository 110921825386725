import React from 'react';
import styled from 'styled-components';

// redux imports
import { connect } from 'react-redux';
import { SetFilters, GetHosts } from '../../redux/actions/hosts';

// resources imports
import {
  greyscale04,
  greyscale10,
  h3,
  magenta10,
  navy07,
  span,
  white
} from '../../resources/consts/styleGuide';
import HeartIcon from '../../resources/images/icons/heart_icon';

class Sidebar extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      favoriteChecked: false,
      industries: [
        'Beauty',
        'Skincare',
        'Health & Wellness',
        'Home & Decor',
        'Food & Beverage',
        'Fashion & Lifestyle',
        'Activewear',
        'Family'
      ],
      industryChecked: []
    };
  }

  setFilters() {
    const { setFilters, getHosts } = this.props;
    const { favoriteChecked, industryChecked } = this.state;

    setFilters({
      favorites: favoriteChecked,
      industries: industryChecked
    });
    getHosts();
  }

  render() {
    const { show, onClose } = this.props;
    const { favoriteChecked, industries, industryChecked } = this.state;

    return (
      <>
        <Scrim
          onClick={() => {
            onClose();
          }}
          show={show}
        />
        <Container show={show}>
          <FavoriteSection
            onClick={() => {
              this.setState({ favoriteChecked: !favoriteChecked }, () => {
                this.setFilters();
              });
            }}
          >
            <FavoriteSelector checked={favoriteChecked}>
              <HeartIcon
                width="14"
                height="14"
                color={favoriteChecked ? white : greyscale04}
              />
            </FavoriteSelector>
            <FavoriteText>My Favorites</FavoriteText>
          </FavoriteSection>
          <IndustrySection>
            <IndustryTitle>Topic / Industry</IndustryTitle>
            {industries &&
              industries.length > 0 &&
              industries.map(industry => (
                <IndustrySelectorSection key={industry}>
                  <IndustrySelector
                    type="checkbox"
                    name={industry}
                    onChange={e => {
                      const industryChange = e.target.name;
                      if (e.target.checked) {
                        this.setState(
                          {
                            industryChecked: [
                              ...industryChecked,
                              industryChange
                            ]
                          },
                          () => {
                            this.setFilters();
                          }
                        );
                      } else {
                        this.setState(
                          {
                            industryChecked: industryChecked.filter(
                              checkedState => checkedState !== industry
                            )
                          },
                          () => {
                            this.setFilters();
                          }
                        );
                      }
                    }}
                  />
                  <IndustryText>{industry}</IndustryText>
                </IndustrySelectorSection>
              ))}
          </IndustrySection>
        </Container>
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

const mapDispatchToProps = dispatch => ({
  setFilters: filters => dispatch(SetFilters(filters)),
  getHosts: () => dispatch(GetHosts())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Sidebar);

const Scrim = styled.div`
  height: 100%;
  width: 100%;

  background-color: ${greyscale10};
  opacity: 50%;

  position: fixed;
  top: 0px;
  left: 0px;

  z-index: 999997;

  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const Container = styled.div`
  width: 350px;
  height: 100%;

  display: flex;
  flex-direction: column;
  row-gap: 40px;

  padding: 30px 36px;

  background-color: ${white};

  position: fixed;
  z-index: 999998;

  visibility: ${props => (props.show ? 'visible' : 'hidden')};
`;

const FavoriteSection = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;

  margin-top: 64px;

  column-gap: 10px;

  :hover {
    opacity: 0.7;
  }

  cursor: pointer;
`;

const FavoriteSelector = styled.button`
  width: 42px;
  height: 40px;

  background-color: ${props => (props.checked ? magenta10 : white)};

  border: ${props =>
    props.checked ? `1px solid ${magenta10}` : `1px solid ${greyscale04}`};
  box-sizing: border-box;
  border-radius: 4px;

  cursor: pointer;
`;

const FavoriteText = styled(h3)`
  color: ${greyscale04};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
`;

const IndustrySection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  row-gap: 30px;
`;

const IndustryTitle = styled(h3)`
  color: ${greyscale04};
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
`;

const IndustrySelectorSection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  column-gap: 13px;
`;

const IndustrySelector = styled.input`
  width: 14px;
  height: 14px;

  background-color: ${white};

  border: 2px solid ${greyscale04};
  box-sizing: border-box;
  border-radius: 1px;

  margin: 0px;

  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  cursor: pointer;
  outline: 0;

  :after {
    content: '';
    position: relative;
    height: 6px;
    width: 2px;
    left: 30%;
    bottom: 0%;
    border: 2px solid ${navy07};
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);
    display: none;
  }

  :checked:after {
    display: block;
  }

  :hover {
    opacity: 0.7;
  }
`;

const IndustryText = styled(span)`
  color: ${greyscale04};
`;
