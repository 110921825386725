// eslint-disable-next-line import/extensions
import { version } from '../../../package.json';

export const SignUp = credentials => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  return firebase
    .auth()
    .createUserWithEmailAndPassword(credentials.email, credentials.password)
    .then(async authUser => {
      firebase.analytics.logEvent('sign_up');

      const currentDate = new Date();
      const timezone = -currentDate.getTimezoneOffset() / 60;

      firestore
        .collection('users')
        .doc(authUser.user.uid)
        .set({
          lastUsage: currentDate,
          timezone,
          id: authUser.user.uid,
          email: credentials.email,
          version: firestore.FieldValue.arrayUnion({ platform: 'web', version })
        });

      return true;
    })
    .catch(error => {
      dispatch({ type: 'SIGNUP_ERROR', error });
      return false;
    });
};

export const LogIn = credentials => (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();

  return firebase
    .auth()
    .signInWithEmailAndPassword(credentials.email, credentials.password)
    .then(() => {
      firebase.analytics().logEvent('login');
      dispatch({ type: 'SIGNIN_SUCCESS' });

      return true;
    })
    .catch(error => {
      if (
        error.code === 'auth/wrong-password' ||
        error.code === 'auth/user-not-found'
      ) {
        dispatch({
          type: 'SIGNIN_ERROR',
          error: {
            ...error,
            message: 'Email address or password is incorrect.'
          }
        });
      } else {
        dispatch({ type: 'SIGNIN_ERROR', error });
      }
      return false;
    });
};

export const SignOut = () => (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  firebase
    .auth()
    .signOut()
    .then(() => {
      dispatch({ type: 'SIGNOUT_SUCCESS' });
    })
    .catch(error => {
      dispatch({ type: 'SIGNOUT_ERROR', error });
    });
};

export const ForgotPassword = email => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  firebase
    .auth()
    .sendPasswordResetEmail(email)
    .then(() => {
      dispatch({
        type: 'FORGOT_PASSWORD_SUCCESS',
        error: { code: false, message: 'Email sent!' }
      });
    })
    .catch(error => {
      dispatch({ type: 'FORGOT_PASSWORD_ERROR', error });
    });
};

export const ChangeEmailAddress = email => (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  return firebase
    .auth()
    .currentUser.updateEmail(email)
    .then(() => {
      dispatch({
        type: 'CHANGE_EMAIL_SUCCESS',
        error: { code: false, message: 'Email changed!' }
      });
    })
    .then(() => {
      firestore
        .collection('users')
        .doc(getState().firebase.auth.uid)
        .update({ email });
      return true;
    })
    .catch(error => {
      dispatch({ type: 'CHANGE_EMAIL_ERROR', error });
      return false;
    });
};

export const ChangePassword = password => (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  return firebase
    .auth()
    .currentUser.updatePassword(password)
    .then(() => {
      dispatch({
        type: 'CHANGE_PASSWORD_SUCCESS',
        error: { code: false, message: 'Password changed!' }
      });
      return true;
    })
    .catch(error => {
      dispatch({ type: 'CHANGE_PASSWORD_ERROR', error });
      return false;
    });
};

export const DeleteAccount = () => (dispatch, getState, { getFirebase }) => {
  const firebase = getFirebase();
  firebase
    .auth()
    .currentUser.delete()
    .then(() => {})
    .catch(error => {
      dispatch({ type: 'ACCOUNT_DELETED_ERROR', error });
    });
};

export const SetAuthError = error => dispatch => {
  dispatch({ type: 'SET_AUTH_ERROR', error });
};

export const ClearAuthError = () => dispatch => {
  dispatch({ type: 'CLEAR_AUTH_ERROR' });
};
