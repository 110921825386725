/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */

export const GetUser = userId => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  if (userId) {
    const user = await firestore
      .collection('users')
      .doc(userId)
      .get();

    const userData = user.data();

    const favoritesSnapshot = await firestore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .get();

    const favorites = [];
    favoritesSnapshot.forEach(async doc => {
      const favoriteData = doc.data();
      await favoriteData.reference.get().then(favoriteDoc => {
        favorites.push(favoriteDoc.data());
      });

      if (favorites.length === favoritesSnapshot.size) {
        dispatch({
          type: 'GET_USER',
          action: { ...userData, favorites }
        });
      }
    });

    dispatch({ type: 'GET_USER', action: { ...userData, favorites: [] } });
    return { ...userData, favorites: [] };
  }

  return {};
};

export const AddFavorite = hostId => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  const { user } = getState();
  const { id: userId } = user;

  if (hostId) {
    firebase.analytics().logEvent('add_host_favorite', { host_id: hostId });

    await firestore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .doc()
      .set({ reference: firestore.collection('users').doc(hostId) });

    const favoritesSnapshot = await firestore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .get();

    const favorites = [];
    favoritesSnapshot.forEach(async doc => {
      const favoriteData = doc.data();
      await favoriteData.reference.get().then(favoriteDoc => {
        favorites.push(favoriteDoc.data());
      });

      if (favorites.length === favoritesSnapshot.size) {
        dispatch({
          type: 'GET_USER',
          action: { ...user, favorites }
        });
      }
    });

    dispatch({ type: 'GET_USER', action: { ...user } });
  }
};

export const RemoveFavorite = hostId => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();
  const { user } = getState();
  const { id: userId } = user;

  if (hostId) {
    firebase.analytics().logEvent('remove_host_favorite', { host_id: hostId });

    const hostReference = await firestore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .where('reference', '==', firestore.collection('users').doc(hostId))
      .get();

    hostReference.docs.forEach(doc => doc.ref.delete());

    const favoritesSnapshot = await firestore
      .collection('users')
      .doc(userId)
      .collection('favorites')
      .get();

    const favorites = [];

    if (favoritesSnapshot.empty) {
      return dispatch({ type: 'GET_USER', action: { ...user, favorites: [] } });
    }

    favoritesSnapshot.forEach(async doc => {
      const favoriteData = doc.data();
      await favoriteData.reference.get().then(favoriteDoc => {
        favorites.push(favoriteDoc.data());
      });

      if (favorites.length === favoritesSnapshot.size) {
        dispatch({
          type: 'GET_USER',
          action: { ...user, favorites }
        });
      }
    });

    return dispatch({ type: 'GET_USER', action: { ...user } });
  }

  return {};
};
