const initState = {
  profiles: [],
  filters: {
    favorites: false,
    industries: []
  },
  query: 'date',
  direction: 'desc'
};

const hostsReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_HOSTS': {
      return { ...state, profiles: action.profiles };
    }
    case 'SET_FILTERS': {
      return { ...state, filters: action.filters };
    }
    case 'SET_SORT_BY': {
      return { ...state, query: action.query, direction: action.direction };
    }
    default:
      return state;
  }
};

export default hostsReducer;
