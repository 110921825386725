/* eslint-disable no-unused-vars */
/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import millify from 'millify';
import firebase from 'firebase/app';

// redux imports
import { connect } from 'react-redux';
import { AddFavorite, RemoveFavorite } from '../../redux/actions/user';

// resources imports
import {
  h3,
  white,
  greyscale07,
  greyscale04,
  normal,
  aqua02,
  navy10,
  magenta09,
  aqua10,
  yellow02,
  yellow10,
  greyscale02
} from '../../resources/consts/styleGuide';
import touchIcon from '../../resources/images/icons/get_in_touch_icon.svg';
import HeartIcon from '../../resources/images/icons/heart_icon';
import PlayIcon from '../../resources/images/icons/play_icon';

// utilities imports
import { getCountryInfo } from '../../utilities/country';
import GetInTouchModal from '../modal/GetInTouchModal';
import HoverInfo from '../global/HoverInfo';

class HostCard extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      playing: false,
      light: props.profile.profile_image,
      showGetInTouch: false,
      showGetInTouchInfo: false,
      showFavoriteInfo: false
    };

    this.videoDelay = null;
  }

  showModal = () => {
    this.setState({ showGetInTouch: true });
  };

  hideModal = () => {
    this.setState({ showGetInTouch: false });
  };

  showHoverInfo = name => {
    if (name === 'getInTouch') {
      this.setState({ showGetInTouchInfo: true });
    } else if (name === 'favorite') {
      this.setState({ showFavoriteInfo: true });
    }
  };

  hideHoverInfo = name => {
    if (name === 'getInTouch') {
      this.setState({ showGetInTouchInfo: false });
    } else if (name === 'favorite') {
      this.setState({ showFavoriteInfo: false });
    }
  };

  render() {
    const {
      key,
      profile,
      isFavorite,
      addFavorite,
      removeFavorite,
      withPlayer,
      clickable,
      onClick
    } = this.props;
    const {
      playing,
      light,
      showGetInTouch,
      showGetInTouchInfo,
      showFavoriteInfo
    } = this.state;

    const hostCountry = getCountryInfo(
      profile.demographics ? profile.demographics.country : null
    );

    const primaryVideo =
      profile.videos && profile.videos.length > 0
        ? profile.videos.find(video => video.is_primary)
        : { url: '' };

    return (
      <>
        <Container>
          <Card onClick={onClick} clickable={clickable}>
            {clickable ? (
              <Player
                url={primaryVideo.url}
                width="100%"
                height="174px"
                light={light}
                playIcon={withPlayer ? <PlayIcon opacity="0.7" /> : <></>}
                playsinline
                playing={playing}
                muted
                onStart={() => {
                  firebase.analytics().logEvent('video_played_on_hover', {
                    host_id: profile.id
                  });
                }}
                onMouseOver={() => {
                  if (withPlayer) {
                    this.videoDelay = setTimeout(() => {
                      this.setState({ playing: true, light: false });
                    }, 500);
                  }
                }}
                onMouseOut={() => {
                  if (withPlayer) {
                    clearTimeout(this.videoDelay);
                    this.setState({
                      playing: false,
                      light: profile.profile_image
                    });
                  }
                }}
              />
            ) : (
              <ProfileBanner banner={profile.profile_image} />
            )}
            <ProfileCircle>
              <ProfileImage src={profile.profile_image} />
            </ProfileCircle>
            <InfoSection>
              <HostSection>
                <HostNameSection>
                  <HostName>{profile.name}</HostName>
                  <HostCountrySection>
                    <HostCountryIcon src={hostCountry.flagUrl} />
                    <HostCountry>{hostCountry.name}</HostCountry>
                  </HostCountrySection>
                </HostNameSection>
                <CTASection>
                  <CTAButton
                    hoverColor={navy10}
                    onClick={event => {
                      event.stopPropagation();
                      this.showModal();
                    }}
                    onMouseOver={event => {
                      event.stopPropagation();
                      this.showHoverInfo('getInTouch');
                    }}
                    onMouseOut={event => {
                      event.stopPropagation();
                      this.hideHoverInfo('getInTouch');
                    }}
                  >
                    <HoverInfo
                      width={62}
                      text="Get In Touch"
                      visible={showGetInTouchInfo}
                    />
                    <CTAIcon src={touchIcon} />
                  </CTAButton>
                  <CTAButton
                    isFavorite={isFavorite}
                    hoverColor={magenta09}
                    onClick={event => {
                      event.stopPropagation();
                      if (isFavorite) {
                        removeFavorite(profile.id);
                      } else {
                        addFavorite(profile.id);
                      }
                    }}
                    onMouseOver={event => {
                      event.stopPropagation();
                      this.showHoverInfo('favorite');
                    }}
                    onMouseOut={event => {
                      event.stopPropagation();
                      this.hideHoverInfo('favorite');
                    }}
                  >
                    <HeartIcon />
                    <HoverInfo
                      width={isFavorite ? 52 : 42}
                      text={isFavorite ? 'Unfavorite' : 'Favorite'}
                      visible={showFavoriteInfo}
                    />
                  </CTAButton>
                </CTASection>
              </HostSection>
              <TagSection>
                {profile.tags &&
                  profile.tags.map(tag => (
                    <Tag key={tag.text} certified={tag.type === 'performance'}>
                      <TagText certified={tag.type === 'performance'}>
                        {tag.text}
                      </TagText>
                    </Tag>
                  ))}
              </TagSection>
              <Line />
              <StatsSection>
                <StatsRow>
                  <Stats>
                    <StatsText>Rating</StatsText>
                    <StatsText bold>
                      {profile.stats && profile.stats.rating
                        ? `${profile.stats.rating}/5`
                        : '-'}
                    </StatsText>
                  </Stats>
                  <Stats>
                    <StatsText>Trusted By</StatsText>
                    <StatsText bold>
                      {profile.stats && profile.stats.trusted_by
                        ? `${millify(profile.stats.trusted_by)} Brands`
                        : '-'}
                    </StatsText>
                  </Stats>
                </StatsRow>
                <StatsRow>
                  <Stats>
                    <StatsText>Total Events</StatsText>
                    <StatsText bold>
                      {profile.stats && profile.stats.events
                        ? millify(profile.stats.events)
                        : '-'}
                    </StatsText>
                  </Stats>
                  <Stats>
                    <StatsText>Total Viewers</StatsText>
                    <StatsText bold>
                      {profile.stats && profile.stats.viewers
                        ? millify(profile.stats.viewers)
                        : '-'}
                    </StatsText>
                  </Stats>
                </StatsRow>
              </StatsSection>
            </InfoSection>
          </Card>
        </Container>
        {showGetInTouch && (
          <GetInTouchModal
            key={key}
            onClose={this.hideModal}
            hostName={profile.name}
            hostId={profile.id}
          />
        )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  addFavorite: hostId => dispatch(AddFavorite(hostId)),
  removeFavorite: hostId => dispatch(RemoveFavorite(hostId))
});

export default connect(
  null,
  mapDispatchToProps
)(HostCard);

const Container = styled.div`
  height: 464px;

  display: flex;
  flex-direction: column;
`;

const Card = styled.div`
  width: 310px;
  height: 464px;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  cursor: ${props => (props.clickable ? 'pointer' : 'default')};

  :hover {
    box-shadow: ${props =>
      props.clickable ? '2px 2px 10px 2px rgba(0, 0, 0, 0.3)' : ''};
  }

  text-decoration-line: none;
`;

const Player = styled(ReactPlayer)`
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;
`;

const ProfileBanner = styled.div`
  width: 100%;
  height: 174px;

  display: flex;
  align-items: center;
  justify-content: center;

  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  overflow: hidden;

  background-image: url(${props => props.banner});
  background-size: cover;
  background-position: center;
  cursor: default;
`;

const ProfileCircle = styled.div`
  width: 80px;
  height: 80px;

  max-height: 80px;

  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  top: -40px;
  right: -20px;

  margin-bottom: -40px; // This is a stick to compensate the top: -40px

  background-color: ${white};

  border-radius: 100%;
`;

const ProfileImage = styled.img`
  width: 70px;
  height: 70px;

  border-radius: 100%;
`;

const InfoSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  box-sizing: border-box;

  padding: 15px;
`;

const HostSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HostNameSection = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const HostName = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 10px;
`;

const HostCountrySection = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const HostCountryIcon = styled.img`
  width: 12px;
  height: 12px;

  border-radius: 100%;

  margin-right: 10px;
`;

const HostCountry = styled(normal)`
  color: ${greyscale07};
`;

const CTASection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const CTAButton = styled.button`
  width: 24px;
  height: 24px;

  background-color: ${props => (props.isFavorite ? magenta09 : greyscale04)};

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 100%;
  border: none;
  padding: 0px;

  margin: 5px;

  cursor: pointer;

  position: relative;

  :hover {
    background-color: ${props => props.hoverColor};
    opacity: ${props => (props.isFavorite ? 0.7 : 1)};
  }
`;

const CTAIcon = styled.img``;

const TagSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  column-gap: 10px;

  margin-top: 16px;
`;

const Tag = styled.div`
  background-color: ${props => (props.certified ? yellow02 : aqua02)};

  border-radius: 2px;
  padding: 4px 10px;
  box-sizing: border-box;

  overflow: hidden;
  white-space: nowrap;
`;

const TagText = styled(normal)`
  color: ${props => (props.certified ? yellow10 : aqua10)};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;

  margin: 15px 0px 0px 0px;

  background-color: ${greyscale02};
`;

const StatsSection = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`;

const StatsRow = styled.div`
  width: 100%;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  margin-top: 16px;
`;

const Stats = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;

  margin-top: 16px;
`;

const StatsText = styled(normal)`
  color: ${greyscale07};
  font-size: 12px;

  font-weight: ${props => (props.bold ? 'bold' : 'normal')};
`;
