const initState = {};

const userReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_USER': {
      return { ...state, ...action.action };
    }
    default:
      return state;
  }
};

export default userReducer;
