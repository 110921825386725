import React from 'react';
import styled from 'styled-components';

// resources imports
import {
  h3,
  normal,
  greyscale07,
  white,
  greyscale05
} from '../../../resources/consts/styleGuide';

class Description extends React.PureComponent {
  render() {
    const { name, description } = this.props;
    return (
      <Container>
        <Title>{`Who is ${name}`}</Title>
        <Paragraph>{description}</Paragraph>
      </Container>
    );
  }
}

export default Description;

const Container = styled.div`
  max-width: 1020px;
  min-height: 120px;

  display: flex;
  flex-direction: column;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
`;

const Title = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 5px;
`;

const Paragraph = styled(normal)`
  color: ${greyscale05};

  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  letter-spacing: 0em;
  text-align: left;
`;
