import React from 'react';
import styled from 'styled-components';
import ReactDatePicker from 'react-datepicker';

// resources imports
import {
  greyscale01,
  greyscale04,
  greyscale07
} from '../../../resources/consts/styleGuide';

import './datepicker.css';

class DatePicker extends React.PureComponent {
  render() {
    const { selectedDate, onChange } = this.props;

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
      <Button
        onClick={onClick}
        ref={ref}
        style={{
          borderColor: `1px solid ${greyscale01}`,
          color: selectedDate ? `${greyscale07}` : `${greyscale04}`
        }}
      >
        {value || 'Click to Select A Date'}
      </Button>
    ));

    return (
      <ReactDatePicker
        selected={selectedDate}
        dateFormat="MMMM d, yyyy"
        isClearable
        onChange={onChange}
        placeholderText="Click to Select A Date"
        customInput={<CustomInput />}
      />
    );
  }
}

export default DatePicker;

const Button = styled.button`
  width: 260px;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;

  background: #ffffff;

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  letter-spacing: 0.05em;
`;
