export const GetHosts = () => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  const {
    user: { favorites },
    hosts: {
      filters: { favorites: favoriteFilter, industries }
    }
  } = getState();

  const industryFilters = [];
  if (industries && industries.length) {
    firebase.analytics().logEvent('filter_selected', { filters: industries });

    industries.forEach(filter => {
      industryFilters.push({
        text: filter,
        type: 'industry'
      });
    });
  }

  const favoriteFilters = [];
  if (favorites && favorites.length) {
    favorites.forEach(favorite => {
      favoriteFilters.push(favorite.id);
    });
  }

  let snapshot;
  if (industryFilters.length > 0) {
    snapshot = await firestore
      .collection('users')
      .where('type', '==', 'host')
      .where('is_private', '==', false)
      .where('tags', 'array-contains-any', industryFilters)
      .get();
  } else {
    snapshot = await firestore
      .collection('users')
      .where('type', '==', 'host')
      .where('is_private', '==', false)
      .get();
  }

  if (snapshot.isEmpty) {
    dispatch({ type: 'GET_HOSTS', profiles: [] });
    return [];
  }

  const hostList = snapshot.docs.map(doc => doc.data());

  if (favoriteFilter) {
    const intersection = hostList.filter(element =>
      favoriteFilters.includes(element.id)
    );

    dispatch({ type: 'GET_HOSTS', profiles: intersection });
    return intersection;
  }

  dispatch({ type: 'GET_HOSTS', profiles: hostList });
  return hostList;
};

export const SetFilters = filters => dispatch => {
  dispatch({ type: 'SET_FILTERS', filters });
};

export const SetSortBy = (query, direction) => dispatch => {
  dispatch({ type: 'SET_SORT_BY', query, direction });
};
