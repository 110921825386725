import React, { useState } from 'react';

// utilities import
import { generateId } from '../../../utilities/generator';

const Prices = ({ onChange, nextPricing }) => {
  const [prices, setPrice] = useState(nextPricing);

  return (
    <div>
      <button
        style={{ marginBottom: '20px' }}
        type="button"
        onClick={() => {
          setPrice(currentPrices => [
            ...currentPrices,
            {
              id: generateId(),
              flat_fee: 0,
              commission: 0,
              type: 'base',
              name: '',
              currency: 'USD'
            }
          ]);
        }}
      >
        Add new Price
      </button>
      {prices.map(price => (
        <div style={{ marginBottom: '20px' }} key={price.id}>
          <label htmlFor="flat_fee" style={{ marginRight: '20px' }}>
            Flat Fee $USD:
            <input
              id="flat_fee"
              onChange={e => {
                const flatFee = Number(e.target.value);
                setPrice(currentPrices =>
                  currentPrices.map(currentPrice =>
                    currentPrice.id === price.id
                      ? {
                          ...currentPrice,
                          flat_fee: flatFee
                        }
                      : currentPrice
                  )
                );
              }}
              type="number"
              value={price.flat_fee}
              placeholder="flat_fee"
            />
          </label>
          <label htmlFor="commission" style={{ marginRight: '20px' }}>
            Commission (ex. 0.25 for 25%):
            <input
              id="commission"
              onChange={e => {
                const commission = Number(e.target.value);
                setPrice(currentPrices =>
                  currentPrices.map(currentPrice =>
                    currentPrice.id === price.id
                      ? {
                          ...currentPrice,
                          commission
                        }
                      : currentPrice
                  )
                );
              }}
              type="number"
              value={price.commission}
            />
          </label>
          <button
            style={{ marginRight: '20px' }}
            type="button"
            onClick={() => {
              onChange(prices);
            }}
          >
            Update Pricing
          </button>
          <button
            type="button"
            onClick={() => {
              setPrice(currentPrices =>
                currentPrices.filter(
                  currentPrice => currentPrice.id !== price.id
                )
              );
            }}
          >
            Remove Price
          </button>
        </div>
      ))}
    </div>
  );
};

export default Prices;
