import React, { PureComponent } from 'react';
import styled from 'styled-components';
import { greyscale05 } from '../resources/consts/styleGuide';

export default class ErrorNotFound extends PureComponent {
  render() {
    // eslint-disable-next-line no-undef
    document.title = `Marketplace - Oops`;
    return (
      <Container>
        <Title>Oops! That page can’t be found.</Title>
        <Text>
          It looks like nothing was found at this location. Maybe try one of the
          links in the menu or press back to go to the previous page.
        </Text>
      </Container>
    );
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0px;
`;

const Title = styled.h1`
  color: ${greyscale05};
  font-family: 'Open Sans';
  font-size: 2rem;
  margin: 10px;
`;

const Text = styled.h3`
  color: ${greyscale05};
  font-family: 'Open Sans';
  font-weight: 100;
  margin: 0px;
`;
