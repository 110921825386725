import React from 'react';
import styled from 'styled-components';
import { Redirect } from 'react-router-dom';
// redux imports
import { connect } from 'react-redux';

// resources imports
// import { normal, greyscale05 } from '../../resources/consts/styleGuide';
import { LOGIN } from '../../resources/consts/routes';
import Videos from '../../components/host/creation/Videos';
import Socials from '../../components/host/creation/Socials';
import Tags from '../../components/host/creation/Tags';
import Prices from '../../components/host/creation/Prices';
import { AddHost, AddHostPicture } from '../../redux/actions/host';
import { GetHosts } from '../../redux/actions/hosts';
import { generateId } from '../../utilities/generator';

class HostCreation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hosts: [],
      hostProfile: {
        name: '',
        description: '',
        email: '',
        from: '',
        is_private: true,
        type: 'host',
        stats: {
          viewers: 0,
          events: 0,
          trusted_by: 0,
          rating: 0,
          stream_time: 0
        },
        demographics: {
          country: '',
          city: '',
          primary_audience_country: '',
          secondary_audience_country: '',
          timezone: '',
          languages: ['English']
        },
        videos: [],
        socials: [],
        tags: [],
        pricing: []
      },
      update: false,
      create: false,
      selectedHost: ''
    };
  }

  async componentDidMount() {
    const { getHosts } = this.props;

    // eslint-disable-next-line no-undef
    document.title = `Marketplace - My Profile`;

    const hosts = await getHosts();
    this.setState({ hosts });
  }

  handleChange = event => {
    const { hostProfile } = this.state;

    this.setState({
      hostProfile: { ...hostProfile, [event.target.name]: event.target.value }
    });
  };

  handleStatsChange = event => {
    const { hostProfile } = this.state;

    this.setState({
      hostProfile: {
        ...hostProfile,
        stats: {
          ...hostProfile.stats,
          [event.target.name]: Number(event.target.value)
        }
      }
    });
  };

  handleDemographicsChange = event => {
    const { hostProfile } = this.state;

    this.setState({
      hostProfile: {
        ...hostProfile,
        demographics: {
          ...hostProfile.demographics,
          [event.target.name]: event.target.value
        }
      }
    });
  };

  handleSubmit = event => {
    const { addHost } = this.props;
    event.preventDefault();
    const { hostProfile, update } = this.state;

    hostProfile.tags.forEach(tag => {
      // eslint-disable-next-line no-param-reassign
      delete tag.id;
    });

    if (update) {
      console.log('UPDATE', hostProfile);
      addHost(hostProfile);

      this.setState({
        update: false,
        selectedHost: '',
        hostProfile: {
          id: '',
          name: '',
          description: '',
          email: '',
          from: '',
          is_private: true,
          type: 'host',
          stats: {
            viewers: 0,
            events: 0,
            trusted_by: 0,
            rating: 0,
            stream_time: 0
          },
          demographics: {
            country: '',
            primary_audience_country: '',
            secondary_audience_country: '',
            timezone: '',
            languages: ['English']
          },
          videos: [],
          socials: [],
          tags: [],
          pricing: []
        }
      });
    } else {
      addHost(hostProfile);

      this.setState({
        create: false,
        selectedHost: '',
        hostProfile: {
          id: '',
          name: '',
          description: '',
          email: '',
          from: '',
          is_private: true,
          type: 'host',
          stats: {
            viewers: 0,
            events: 0,
            trusted_by: 0,
            rating: 0,
            stream_time: 0
          },
          demographics: {
            country: '',
            primary_audience_country: '',
            secondary_audience_country: '',
            timezone: '',
            languages: ['English']
          },
          videos: [],
          socials: [],
          tags: [],
          pricing: []
        }
      });
    }
  };

  render() {
    const { auth, addHost, addHostPicture } = this.props;
    const { hostProfile, update, create, selectedHost, hosts } = this.state;

    if (!auth.uid) {
      return <Redirect to={LOGIN} />;
    }

    return (
      <Container>
        {!update && !create && (
          <>
            <button
              style={{ width: '100px', height: '40px', marginRight: '100px' }}
              type="button"
              onClick={async () => {
                this.setState({
                  update: true
                });
              }}
            >
              Update Host
            </button>
            <button
              style={{ width: '100px', height: '40px' }}
              type="button"
              onClick={async () => {
                const host = await addHost({
                  ...hostProfile,
                  id: generateId(),
                  is_private: true
                });
                this.setState({
                  hostProfile: { ...hostProfile, id: host.id },
                  create: true
                });
              }}
            >
              Add new Host
            </button>
          </>
        )}
        {update && (
          <label htmlFor="name" style={{ marginRight: '20px' }}>
            Update an host:
            <select
              id="host"
              name="host"
              onChange={e => {
                const hostId = e.target.value;
                this.setState({
                  selectedHost: hostId,
                  update: true,
                  hostProfile: hosts.find(host => host.id === hostId)
                });
              }}
              value={selectedHost}
            >
              <option value="">Choose an Host</option>
              {hosts.map(host => (
                <option value={host.id}>{host.name}</option>
              ))}
            </select>
          </label>
        )}
        {hostProfile.id && (update || create) && (
          <div>
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              onChange={async event => {
                const uploadedFile = event?.target.files[0];
                if (!uploadedFile) return;

                const result = await addHostPicture(
                  hostProfile.id,
                  uploadedFile
                );

                if (!result.error) {
                  this.setState({
                    hostProfile: {
                      ...hostProfile,
                      profile_image: result.data
                    }
                  });
                } else {
                  // eslint-disable-next-line no-alert
                  alert(result.data);
                }
              }}
            />
            <HostForm
              innerRef={form => {
                this.formRef = form;
              }}
              onSubmit={this.handleSubmit}
            >
              <Section>
                <Label>Basic Info Section</Label>
                <SimpleInput
                  type="text"
                  name="name"
                  placeholder="Name"
                  value={hostProfile.name}
                  onChange={this.handleChange}
                />
                <SimpleInput
                  type="email"
                  name="email"
                  placeholder="Host email"
                  value={hostProfile.email}
                  onChange={this.handleChange}
                />
                <SimpleInput
                  type="text"
                  name="from"
                  placeholder="where did you fetch them"
                  value={hostProfile.from}
                  onChange={this.handleChange}
                />
                <Label>Is private?</Label>
                <SimpleInput
                  type="checkbox"
                  name="private"
                  checked={hostProfile.is_private}
                  onChange={event => {
                    const { checked } = event.target;
                    this.setState({
                      hostProfile: { ...hostProfile, is_private: checked }
                    });
                  }}
                />
                <TextArea
                  name="description"
                  placeholder="Description"
                  value={hostProfile.description}
                  onChange={this.handleChange}
                  form={this.formRef}
                />
              </Section>
              <Section>
                <Label>Stats Section</Label>
                <div>
                  <Label>Number of viewers</Label>
                  <SimpleInput
                    type="number"
                    name="viewers"
                    value={hostProfile.stats.viewers}
                    onChange={this.handleStatsChange}
                  />
                </div>
                <div>
                  <Label>Number of events</Label>
                  <SimpleInput
                    type="number"
                    name="events"
                    value={hostProfile.stats.events}
                    onChange={this.handleStatsChange}
                  />
                </div>
                <div>
                  <Label>Number of trusted brands</Label>
                  <SimpleInput
                    type="number"
                    name="trusted_by"
                    value={hostProfile.stats.trusted_by}
                    onChange={this.handleStatsChange}
                  />
                </div>
                <div>
                  <Label>Rating on 5</Label>
                  <SimpleInput
                    type="number"
                    name="rating"
                    placeholder="Rating"
                    value={hostProfile.stats.rating}
                    onChange={this.handleStatsChange}
                  />
                </div>
                <div>
                  <Label>Stream Time (ms)</Label>
                  <SimpleInput
                    type="number"
                    name="stream_time"
                    value={hostProfile.stats.stream_time}
                    onChange={this.handleStatsChange}
                  />
                </div>
              </Section>
              <Section>
                <Label>Demographics Section</Label>
                <SimpleInput
                  type="text"
                  name="country"
                  placeholder="Country ISO2CODE (CA, US, MX)"
                  value={hostProfile.demographics.country}
                  onChange={this.handleDemographicsChange}
                />
                <SimpleInput
                  type="text"
                  name="city"
                  placeholder="City"
                  value={hostProfile.demographics.city}
                  onChange={this.handleDemographicsChange}
                />
                <SimpleInput
                  type="text"
                  name="primary_audience_country"
                  placeholder="Primary audience country ISO2CODE (CA, US, MX)"
                  value={hostProfile.demographics.primary_audience_country}
                  onChange={this.handleDemographicsChange}
                />
                <SimpleInput
                  type="text"
                  name="secondary_audience_country"
                  placeholder="Secondary audience country ISO2CODE (CA, US, MX)"
                  value={hostProfile.demographics.secondary_audience_country}
                  onChange={this.handleDemographicsChange}
                />
                <SimpleInput
                  type="text"
                  name="timezone"
                  placeholder="timezone (America/Toronto)"
                  value={hostProfile.demographics.timezone}
                  onChange={this.handleDemographicsChange}
                />
              </Section>
              <Section>
                <Label>Video Section</Label>
                <Videos
                  nextVideos={hostProfile.videos}
                  onChange={videos => {
                    if (videos !== hostProfile.videos) {
                      this.setState({
                        hostProfile: { ...hostProfile, videos }
                      });
                    }
                  }}
                />
              </Section>
              <Section>
                <Label>Socials Section</Label>
                <Socials
                  nextSocials={hostProfile.socials}
                  onChange={socials => {
                    if (socials !== hostProfile.socials) {
                      this.setState({
                        hostProfile: { ...hostProfile, socials }
                      });
                    }
                  }}
                />
              </Section>
              <Section>
                <Label>Tags Section</Label>
                <Tags
                  nextTags={hostProfile.tags}
                  onChange={tags => {
                    if (tags !== hostProfile.tags) {
                      this.setState({
                        hostProfile: { ...hostProfile, tags }
                      });
                    }
                  }}
                />
              </Section>
              <Section>
                <Label>Prices Section</Label>
                <Prices
                  nextPricing={hostProfile.pricing}
                  onChange={pricing => {
                    if (pricing !== hostProfile.pricing) {
                      this.setState({
                        hostProfile: { ...hostProfile, pricing }
                      });
                    }
                  }}
                />
              </Section>
              <Button type="submit" value={update ? 'Update' : 'Create'} />
            </HostForm>
          </div>
        )}
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

const mapDispatchToProps = dispatch => ({
  getHosts: () => dispatch(GetHosts()),
  addHost: profile => dispatch(AddHost(profile)),
  addHostPicture: (hostId, file) => dispatch(AddHostPicture(hostId, file))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(HostCreation);

const Container = styled.div`
  margin: 42px;

  display: flex;
  justify-content: center;
`;

const HostForm = styled.form`
  display: flex;
  flex-direction: column;
  row-gap: 30px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 10px;
`;

const Label = styled.label``;

const SimpleInput = styled.input``;

const TextArea = styled.textarea``;

const Button = styled.input``;
