import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

// resources imports
import { normal, greyscale05 } from '../../resources/consts/styleGuide';
import livescaleLogo from '../../resources/images/logo/livescale_with_name.svg';
import { HOSTS } from '../../resources/consts/routes';

class Logo extends React.PureComponent {
  render() {
    const { who, what } = this.props;
    return (
      <Container to={HOSTS}>
        <LogoImage src={livescaleLogo} />
        <Bar />
        <LogoTextContainer>
          <LogoText>{who}</LogoText>
          <LogoText>{what}</LogoText>
        </LogoTextContainer>
      </Container>
    );
  }
}

export default Logo;

const Container = styled(Link)`
  width: 242px;
  height: 26px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  text-decoration-line: none;
`;

const LogoImage = styled.img`
  width: 172px;
  height: 24px;
`;

const Bar = styled.div`
  width: 1px;
  height: 26px;

  background: ${greyscale05};
`;

const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LogoText = styled(normal)`
  color: ${greyscale05};
  margin: 0px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
`;
