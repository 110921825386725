import React from 'react';
import styled from 'styled-components';

// resources imports
import {
  greyscale05,
  greyscale07,
  h3,
  normal,
  white
} from '../../../resources/consts/styleGuide';

// utilities imports
import { getCountryInfo } from '../../../utilities/country';
import { getFormattedTimezone } from '../../../utilities/timezone';

class Demographics extends React.PureComponent {
  render() {
    const { demographics } = this.props;

    const primaryAudienceCountry = getCountryInfo(
      demographics ? demographics.primary_audience_country : null
    );

    const secondaryAudienceCountry = getCountryInfo(
      demographics ? demographics.secondary_audience_country : null
    );

    const hostCountry = getCountryInfo(
      demographics ? demographics.country : null
    );

    return (
      <Container>
        <Title>Demographics</Title>
        <SectionContainer>
          <Section>
            <Block>
              <BlockTitle>Primary Audience Country</BlockTitle>
              {primaryAudienceCountry.name ? (
                <BlockValue>
                  <CountryIcon src={primaryAudienceCountry.flagUrl} />
                  <BlockText>{primaryAudienceCountry.name}</BlockText>
                </BlockValue>
              ) : (
                <BlockValue>
                  <BlockText>Unspecified</BlockText>
                </BlockValue>
              )}
            </Block>
            <Block>
              <BlockTitle>Secondary Audience Country</BlockTitle>
              {secondaryAudienceCountry.name ? (
                <BlockValue>
                  <CountryIcon src={secondaryAudienceCountry.flagUrl} />
                  <BlockText>{secondaryAudienceCountry.name}</BlockText>
                </BlockValue>
              ) : (
                <BlockValue>
                  <BlockText>Unspecified</BlockText>
                </BlockValue>
              )}
            </Block>
          </Section>
          <Section>
            <Block>
              <BlockTitle>Host Country</BlockTitle>
              {hostCountry.name ? (
                <BlockValue>
                  <CountryIcon src={hostCountry.flagUrl} />
                  <BlockText>{hostCountry.name}</BlockText>
                </BlockValue>
              ) : (
                <BlockValue>
                  <BlockText>Unspecified</BlockText>
                </BlockValue>
              )}
            </Block>
            <Block>
              <BlockTitle>Host Time Zone</BlockTitle>
              <BlockText>
                {demographics
                  ? getFormattedTimezone(demographics.timezone)
                  : 'Unspecified'}
              </BlockText>
            </Block>
          </Section>
          <Section>
            <Block>
              <BlockTitle>Language(s)</BlockTitle>
              <BlockText>
                {demographics && demographics.languages
                  ? demographics.languages.join(', ')
                  : 'Unspecified'}
              </BlockText>
            </Block>
          </Section>
        </SectionContainer>
      </Container>
    );
  }
}

export default Demographics;

const Container = styled.div`
  max-width: 1020px;
  min-height: 132px;

  display: flex;
  flex-direction: column;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px 100px 20px 30px;
`;

const Title = styled(h3)`
  color: ${greyscale07};
`;

const SectionContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  margin: 20px 0px 0px 0px;
`;

const Section = styled.div`
  display: flex;
  flex-direction: column;

  row-gap: 20px;
`;

const Block = styled.div`
  display: flex;
  flex-direction: column;
`;

const BlockTitle = styled(normal)`
  color: ${greyscale07};
  margin-bottom: 10px;
`;

const BlockValue = styled.div`
  display: flex;
  flex-direction: row;
`;

const CountryIcon = styled.img`
  width: 12px;
  height: 12px;

  border-radius: 100%;

  margin-right: 10px;
`;

const BlockText = styled(normal)`
  color: ${greyscale05};
`;
