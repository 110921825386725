import React from 'react';
import styled from 'styled-components';

// redux imports
import { connect } from 'react-redux';
import { ClearAuthError, ForgotPassword } from '../../redux/actions/auth';

// resources imports
import {
  aqua08,
  greyscale02,
  greyscale04,
  greyscale05,
  greyscale07,
  h3,
  normal,
  orange09,
  white
} from '../../resources/consts/styleGuide';

class FogotPasswordForm extends React.Component {
  constructor(props) {
    super(props);

    const { clearAuthError } = this.props;
    clearAuthError();

    this.state = {
      email: ''
    };
  }

  onSubmitForgotPassword = async event => {
    event.preventDefault();
    const { forgotPassword, onSubmit } = this.props;
    const { email } = this.state;

    forgotPassword(email);
    onSubmit(email);
  };

  onChange = event => {
    const { clearAuthError } = this.props;
    clearAuthError();
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { email } = this.state;

    return (
      <Container>
        <InformationText>
          Enter your email to reset the password.
        </InformationText>
        <Form onSubmit={this.onSubmitForgotPassword}>
          <SubTitle>Work email address</SubTitle>
          <Input
            name="email"
            value={email}
            onChange={this.onChange}
            type="text"
            placeholder="Enter your work email"
            autoComplete="username"
            onKeyDown={e => {
              if (e.key === 'Tab') {
                e.preventDefault();
              }
            }}
          />
          <Button type="submit">
            <ButtonText>Reset Password</ButtonText>
          </Button>
        </Form>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  authError: state.auth.authError
});

const mapDispatchToProps = dispatch => ({
  forgotPassword: email => dispatch(ForgotPassword(email)),
  clearAuthError: () => dispatch(ClearAuthError())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FogotPasswordForm);

const Container = styled.div``;

const Button = styled.button`
  width: 350px;
  height: 34px;

  display: flex;
  justify-content: center;
  align-items: center;

  border-radius: 5px;

  cursor: pointer;

  background-color: ${orange09};
  outline: none;
  margin-top: 30px;
  border: 0px;
  padding: 0px;

  transition: filter 0.2s, box-shadow 0.2s;

  :hover {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  }
  :active {
    box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  }
`;

const Input = styled.input`
  width: 350px;
  height: 40px;

  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 10px;

  background: ${white};
  border: 1px solid ${greyscale02};
  box-sizing: border-box;
  border-radius: 4px;
  padding: 10px;

  color: ${greyscale07};
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 14px;
  margin: 5px 0px 30px 0px;

  ::placeholder {
    color: ${greyscale04};
  }

  :focus {
    outline-color: ${aqua08};
  }
`;

const Form = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-around;
`;

const InformationText = styled(h3)`
  font-size: 14px;
  margin: 0px;

  color: ${greyscale05};

  margin: 10px 0px 40px 0px;
`;

const SubTitle = styled(h3)`
  font-size: 12px;
  margin: 0px;
`;

const ButtonText = styled(normal)`
  color: ${white};
  margin: 0px;

  font-weight: bold;
  font-size: 16px;
`;
