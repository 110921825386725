import React, { useState } from 'react';

// utilities import
import { generateId } from '../../../utilities/generator';

const Socials = ({ onChange, nextSocials }) => {
  const [socials, setSocial] = useState(nextSocials);

  return (
    <div>
      <button
        style={{ marginBottom: '20px' }}
        type="button"
        onClick={() => {
          setSocial(currentSocials => [
            ...currentSocials,
            {
              id: generateId(),
              name: '',
              primary_stat: { name: '', value: 0 },
              link: ''
            }
          ]);
        }}
      >
        Add new Social
      </button>
      {socials &&
        socials.map(social => (
          <div style={{ marginBottom: '20px' }} key={social.id}>
            <label htmlFor="name" style={{ marginRight: '20px' }}>
              Social Name:
              <select
                id="name"
                name="name"
                onChange={e => {
                  const name = e.target.value;
                  setSocial(currentSocials =>
                    currentSocials.map(currentSocial =>
                      currentSocial.id === social.id
                        ? {
                            ...currentSocial,
                            name
                          }
                        : currentSocial
                    )
                  );
                }}
                value={social.name}
              >
                <option value="">Choose a Value</option>
                <option value="instagram">Instagram</option>
                <option value="facebook">Facebook</option>
                <option value="tiktok">Tik Tok</option>
                <option value="youtube">Youtube</option>
                <option value="linkedin">LinkedIn</option>
              </select>
            </label>
            <label htmlFor="link" style={{ marginRight: '20px' }}>
              Social Link:
              <input
                id="link"
                onChange={e => {
                  const link = e.target.value;
                  setSocial(currentSocials =>
                    currentSocials.map(currentSocial =>
                      currentSocial.id === social.id
                        ? {
                            ...currentSocial,
                            link
                          }
                        : currentSocial
                    )
                  );
                }}
                value={social.link}
                placeholder="link"
              />
            </label>
            <label htmlFor="statName" style={{ marginRight: '20px' }}>
              Social Primary Stat Name:
              <input
                id="statName"
                onChange={e => {
                  const statName = e.target.value;

                  setSocial(currentSocials =>
                    currentSocials.map(currentSocial =>
                      currentSocial.id === social.id
                        ? {
                            ...currentSocial,
                            primary_stat: {
                              ...currentSocial.primary_stat,
                              name: statName
                            }
                          }
                        : currentSocial
                    )
                  );
                }}
                value={social.primary_stat.name}
              />
            </label>
            <label htmlFor="statValue" style={{ marginRight: '20px' }}>
              Social Primary Stat Value:
              <input
                id="statValue"
                type="number"
                onChange={e => {
                  const statValue = e.target.value;
                  setSocial(currentSocials =>
                    currentSocials.map(currentSocial =>
                      currentSocial.id === social.id
                        ? {
                            ...currentSocial,
                            primary_stat: {
                              ...currentSocial.primary_stat,
                              value: Number(statValue)
                            }
                          }
                        : currentSocial
                    )
                  );
                }}
                value={social.primary_stat.value}
              />
            </label>
            <button
              style={{ marginRight: '20px' }}
              type="button"
              onClick={() => {
                onChange(socials);
              }}
            >
              Update Social
            </button>
            <button
              type="button"
              onClick={() => {
                setSocial(currentSocials =>
                  currentSocials.filter(
                    currentSocial => currentSocial.id !== social.id
                  )
                );
              }}
            >
              Remove Social
            </button>
          </div>
        ))}
    </div>
  );
};

export default Socials;
