/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
import React from 'react';
import { createGlobalStyle } from 'styled-components';
import { Router, Route, Switch } from 'react-router-dom';
import './App.css';

// pages imports
import ErrorNotFound from './pages/Error';

import LoginIn from './pages/authentification/LogIn';
import SignUp from './pages/authentification/SignUp';
import ForgotPassword from './pages/authentification/ForgotPassword';

import Hosts from './pages/hosts/Hosts';
import HostProfile from './pages/hosts/HostProfile';
import HostCreation from './pages/hosts/HostCreation';

// components imports
import Header from './components/global/Header';
import Footer from './components/global/Footer';
import Setup from './Setup';
import ErrorBoundary from './Error';

// resources imports
import {
  LOGIN,
  SIGNUP,
  FORGOT_PASSWORD,
  HOSTS,
  HOST_PROFILE,
  HOST_CREATION
} from './resources/consts/routes';
import Sidebar from './components/global/Sidebar';

const GlobalStyles = createGlobalStyle`
body {
  margin: 0;
  background-color: rgba(0, 0, 0, 0.04);
  cursor: auto;
  user-select: none;
}

.red-bg {
  background-color: red;
  border-color: 1px solid red;
}
`;

class App extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      component: (
        <div>
          <Setup
            initializationComplete={this.initializationComplete}
            history={props.history}
          />
        </div>
      ),
      sidebarOpen: false
    };
  }

  sidebarOpen = () => {
    this.setState(prevState => ({
      sidebarOpen: !prevState.sidebarOpen
    }));
  };

  initializationComplete = () => {
    const { history } = this.props;
    this.setState({
      component: (
        <ErrorBoundary history={history}>
          <Router history={history}>
            <Header
              onFilterClick={() => {
                this.sidebarOpen();
              }}
            />
            <Switch>
              <Route exact path={LOGIN} component={LoginIn} />
              <Route exact path={SIGNUP} component={SignUp} />
              <Route exact path={FORGOT_PASSWORD} component={ForgotPassword} />
              <Route exact path={HOSTS} component={Hosts} />
              <Route
                exact
                path={`${HOST_PROFILE}/:id`}
                component={HostProfile}
              />
              <Route exact path={HOST_CREATION} component={HostCreation} />
              <Route path="*" component={ErrorNotFound} />
            </Switch>

            <Footer />
          </Router>
        </ErrorBoundary>
      )
    });
  };

  render() {
    const { component, sidebarOpen } = this.state;
    return (
      <div className="App">
        <Sidebar
          show={sidebarOpen}
          onClose={() => {
            this.sidebarOpen(false);
          }}
        />

        <GlobalStyles />
        {component}
      </div>
    );
  }
}

export default App;
