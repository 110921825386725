import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';

import { greyscale05, orange10 } from './resources/consts/styleGuide';
import { HOSTS } from './resources/consts/routes';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, info) {
    firebase.analytics().logEvent('exception', { error, info });
  }

  sendBack = () => {
    const { history } = this.props;

    this.setState({ hasError: false });
    history.replace(`${HOSTS}`);
  };

  render() {
    const { children } = this.props;
    const { hasError } = this.state;

    if (hasError) {
      return (
        <Container className="ErrorBoundary">
          <Title>Oops! Something went wrong.</Title>
          <Text>Let&apos;s make sure this never happen again</Text>
          <Text>Let us a problem description</Text>
          <Button onClick={this.sendBack}>Take me back to the dashboard</Button>
        </Container>
      );
    }
    return children;
  }
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0px;
`;

const Title = styled.h1`
  color: ${greyscale05};
  font-family: 'Open Sans';
  font-size: 2rem;
  margin: 10px;
`;

const Text = styled.h3`
  color: ${greyscale05};
  font-family: 'Open Sans';
  font-weight: 100;
  margin: 0px;
`;

const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-family: 'Open Sans';
  font-weight: 700;
  font-size: 16px;
  color: #ffffff;
  background-color: ${orange10};
  border: 0;
  border-radius: 5px;
  margin: 40px;
  padding: 15px;
  cursor: pointer;

  :focus {
    outline: none;
  }

  :hover {
    opacity: 0.7;
  }
`;
