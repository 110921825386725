import React from 'react';
import styled from 'styled-components';

// redux imports
import { connect } from 'react-redux';

// resources imports
import { white } from '../../resources/consts/styleGuide';
import userPicture from '../../resources/images/header/user_pic_empty.svg';
import filterIcon from '../../resources/images/header/filter.svg';
// import searchIcon from '../../resources/images/header/search.svg';

// components imports
import HeaderModal from '../modal/HeaderModal';
import Logo from './Logo';

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = { show: false };
  }

  showModal = () => {
    this.setState({ show: true });
  };

  hideModal = () => {
    this.setState({ show: false });
  };

  render() {
    const { auth, router, onFilterClick } = this.props;
    const { show } = this.state;

    if (auth.isEmpty) {
      return <></>;
    }

    return (
      <>
        <Container>
          <SubContainer>
            <FilterButton
              onClick={() => {
                onFilterClick();
              }}
              visible={router.location.pathname === '/hosts'}
            >
              <Icon src={filterIcon} />
            </FilterButton>
          </SubContainer>
          <Logo who="Hosts" what="Catalog" />
          <SubContainer>
            {/* <SearchButton>
              <Icon src={searchIcon} />
            </SearchButton> */}
            <UserProfileButton onClick={this.showModal}>
              <Icon src={userPicture} />
            </UserProfileButton>
          </SubContainer>
        </Container>
        {show && <HeaderModal onClose={this.hideModal} />}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  router: state.router
});

export default connect(
  mapStateToProps,
  null
)(Header);

const Container = styled.div`
  height: 64px;
  background-color: ${white};

  padding-left: 42px;
  padding-right: 42px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);

  z-index: 99999999;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const FilterButton = styled.button`
  width: 16px;
  height: 16px;

  background-color: transparent;
  border: none;
  padding: 0px;

  visibility: ${props => (props.visible ? 'visible' : 'hidden')};

  cursor: pointer;
`;

// const SearchButton = styled.button`
//   width: 16px;
//   height: 16px;

//   background-color: transparent;

//   border: none;
//   padding: 0px;

//   margin-right: 20px;
// `;

const UserProfileButton = styled.button`
  width: 32px;
  height: 32px;

  background-color: transparent;

  border-radius: 100%;
  border: none;
  padding: 0px;

  cursor: pointer;
`;

const Icon = styled.img``;
