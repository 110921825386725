/* eslint-disable no-underscore-dangle */
import React from 'react';
import styled from 'styled-components';

// resources imports
import { greyscale08, normal, white } from '../../resources/consts/styleGuide';

class HoverInfo extends React.PureComponent {
  render() {
    const { text, width, visible } = this.props;

    return (
      <Container width={width} visible={visible}>
        <Text>{text}</Text>
      </Container>
    );
  }
}

export default HoverInfo;

const Container = styled.div`
  height: 16px;
  width: ${props => `${props.width}px`};

  background-color: ${greyscale08};

  padding: 0px 6px;
  border-radius: 2px;

  position: absolute;
  z-index: 999;

  top: -21px; /* This is height + distance on top (16px + 5px) */

  visibility: ${props => (props.visible ? 'visible' : 'hidden')};
`;

const Text = styled(normal)`
  color: ${white};
`;
