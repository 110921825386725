const initState = {
  authError: null
};

const authReducer = (state = initState, action) => {
  switch (action.type) {
    case 'SIGNUP_ERROR':
      return { ...state, authError: action.error };
    case 'SIGNIN_ERROR':
      return { ...state, authError: action.error };
    case 'SIGNIN_SUCCESS':
      return { ...state, authError: null };
    case 'SIGNOUT_SUCCESS':
      return state;
    case 'FORGOT_PASSWORD_SUCCESS':
      return { ...state, authError: null };
    case 'FORGOT_PASSWORD_ERROR':
      return { ...state, authError: action.error };
    case 'CHANGE_EMAIL_SUCCESS':
      return { ...state, authError: action.error };
    case 'CHANGE_EMAIL_ERROR':
      return { ...state, authError: action.error };
    case 'CHANGE_PASSWORD_SUCCESS':
      return { ...state, authError: action.error };
    case 'CHANGE_PASSWORD_ERROR':
      return { ...state, authError: action.error };
    case 'ACCOUNT_DELETED_ERROR':
      return { ...state, authError: action.error };
    case 'SET_AUTH_ERROR':
      return { ...state, authError: action.error };
    case 'CLEAR_AUTH_ERROR':
      return { ...state, authError: null };
    default:
      return state;
  }
};

export default authReducer;
