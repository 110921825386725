import React from 'react';

// redux imports
import { connect } from 'react-redux';
import { GetHosts } from './redux/actions/hosts';
import { GetUser } from './redux/actions/user';

class Loading extends React.PureComponent {
  async componentDidMount() {
    const { initializationComplete, auth, getUser, getHosts } = this.props;

    await getUser(auth.uid);
    await getHosts();

    initializationComplete();
  }

  render() {
    return <div />;
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(GetUser(userId)),
  getHosts: () => dispatch(GetHosts())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Loading);
