import React from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';

// redux imports
import { connect } from 'react-redux';

// resources imports
import { greyscale05, white } from '../../resources/consts/styleGuide';
import { HOSTS, SIGNUP } from '../../resources/consts/routes';

// components imports
import Logo from '../../components/global/Logo';
import LoginForm from '../../components/authentification/LoginForm';

class LogIn extends React.PureComponent {
  componentDidMount() {
    // eslint-disable-next-line no-undef
    document.title = `Marketplace - Sign in`;
  }

  render() {
    const { auth } = this.props;

    if (auth.uid && !auth.isAnonymous) return <Redirect to={HOSTS} />;

    return (
      <Container>
        <LogInContainer>
          <Logo who="Hosts" what="Catalog" />
          <div style={{ height: '80px' }} />
          <LoginForm />
          <RedirectLink
            to={SIGNUP}
            style={{
              position: 'absolute',
              left: 'calc(50% - 266px/2)',
              top: 'calc(50% - 18px/2 + 389px)'
            }}
          >
            Don&apos;t have an account yet? Sign up here.
          </RedirectLink>
        </LogInContainer>
        <ImageBackground>
          <ImageOverlay />
        </ImageBackground>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default connect(
  mapStateToProps,
  null
)(LogIn);

const Container = styled.div``;

const ImageBackground = styled.div`
  background-image: url('https://dpas4li76ctjb.cloudfront.net/wp-content/uploads/2021/07/pexels-los-muertos-crew-7261085-1.jpg');

  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;

  filter: blur(10px);
`;

const ImageOverlay = styled.div`
  width: 100vw;
  height: 100vh;

  background: ${white};
  opacity: 0.8;
`;

const LogInContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 50%;
  top: 50%;

  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
`;

const RedirectLink = styled(Link)`
  color: ${greyscale05};
  cursor: pointer;
  text-decoration-line: underline;
  font-size: 12px;
`;
