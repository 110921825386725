import React from 'react';
import styled from 'styled-components';
import millify from 'millify';

// resources imports
import {
  aqua02,
  aqua10,
  greyscale02,
  greyscale05,
  greyscale07,
  h3,
  navy08,
  normal,
  white,
  yellow02,
  yellow10
} from '../../../resources/consts/styleGuide';
import cameraIcon from '../../../resources/images/icons/camera_icon.svg';
import shakeIcon from '../../../resources/images/icons/shake_icon.svg';
import eyeIcon from '../../../resources/images/icons/eye_icon.svg';
import clockIcon from '../../../resources/images/icons/clock_icon.svg';

const TimeFormat = require('hh-mm-ss');

class CoreMetrics extends React.PureComponent {
  render() {
    const { events, trustedBy, viewedBy, streamTime, tags } = this.props;

    const streamingTime = streamTime || 0;
    const roundedStreamingTime = streamingTime - (streamingTime % 1000);

    return (
      <Container>
        <Title>At a glance</Title>
        <MetricsContainer>
          <Metric>
            <IconContainer>
              <Icon src={cameraIcon} />
            </IconContainer>
            <MetricDataSection>
              <MetricValue>{events > 0 ? millify(events) : '-'}</MetricValue>
              <MetricTitle>Livesteams Done</MetricTitle>
            </MetricDataSection>
          </Metric>
          <Metric>
            <IconContainer>
              <Icon src={shakeIcon} />
            </IconContainer>
            <MetricDataSection>
              <MetricValue>
                {trustedBy > 0 ? millify(trustedBy) : '-'}
              </MetricValue>
              <MetricTitle>Trusted By</MetricTitle>
            </MetricDataSection>
          </Metric>
          <Metric>
            <IconContainer>
              <Icon src={eyeIcon} />
            </IconContainer>
            <MetricDataSection>
              <MetricValue>
                {viewedBy > 0 ? millify(viewedBy) : '-'}
              </MetricValue>
              <MetricTitle>Viewed By</MetricTitle>
            </MetricDataSection>
          </Metric>
          <Metric>
            <IconContainer>
              <Icon src={clockIcon} />
            </IconContainer>
            <MetricDataSection>
              <MetricValue>
                {roundedStreamingTime > 0
                  ? TimeFormat.fromMs(roundedStreamingTime, 'hh:mm:ss')
                  : '-'}
              </MetricValue>
              <MetricTitle>Time Streamed</MetricTitle>
            </MetricDataSection>
          </Metric>
        </MetricsContainer>
        <Line />
        <TagSection>
          {tags &&
            tags.map(tag => (
              <Tag key={tag.text} certified={tag.type === 'performance'}>
                <TagText certified={tag.type === 'performance'}>
                  {tag.text}
                </TagText>
              </Tag>
            ))}
        </TagSection>
      </Container>
    );
  }
}

export default CoreMetrics;

const Container = styled.div`
  max-width: 1020px;
  min-height: 132px;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  background-color: ${white};

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  box-sizing: border-box;
  padding: 20px 30px 30px 30px;
`;

const Title = styled(h3)`
  color: ${greyscale07};

  margin-bottom: 20px;
`;

const MetricsContainer = styled.div`
  display: flex;
  flex-direction: grid;
  flex-wrap: wrap;
  justify-content: flex-start;

  column-width: 150px;
  column-gap: 100px;
  row-gap: 20px;

  margin-bottom: 10px;
`;

const Metric = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`;

const IconContainer = styled.div`
  width: 40px;
  height: 40px;

  background: ${navy08};

  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: 10px;

  border-radius: 100%;

  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.08);
`;

const Icon = styled.img`
  width: 40px;
`;

const MetricDataSection = styled.div``;

const MetricTitle = styled(normal)`
  color: ${greyscale05};
`;

const MetricValue = styled(normal)`
  color: ${greyscale07};
  font-weight: bold;
  font-size: 16px;
  line-height: 22px;
`;

const Line = styled.div`
  width: 100%;
  height: 1px;

  margin: 15px 0px 0px 0px;

  background-color: ${greyscale02};
`;

const TagSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;

  column-gap: 10px;

  margin-top: 30px;
`;

const Tag = styled.div`
  background-color: ${props => (props.certified ? yellow02 : aqua02)};

  border-radius: 2px;
  padding: 4px 10px;
  box-sizing: border-box;

  overflow: hidden;
  white-space: nowrap;
`;

const TagText = styled(normal)`
  color: ${props => (props.certified ? yellow10 : aqua10)};

  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`;
