import React from 'react';
import styled from 'styled-components';
import { aqua10, greyscale09 } from '../../../resources/consts/styleGuide';

// resources imports
import PlayIcon from '../../../resources/images/icons/play_icon';

class Thumbnail extends React.PureComponent {
  render() {
    const { url, width, height, playing, fallbackImage, onClick } = this.props;

    let videoId = url.split('v=')[1];

    let thumbnailUrl;
    if (videoId) {
      const ampersandPosition = videoId.indexOf('&');
      if (ampersandPosition !== -1) {
        videoId = videoId.substring(0, ampersandPosition);
      }

      thumbnailUrl = `https://img.youtube.com/vi/${videoId}/0.jpg`;
    } else {
      thumbnailUrl = fallbackImage;
    }

    return (
      <Overlay
        width={width}
        height={height}
        playing={playing}
        onClick={onClick}
      >
        <PlayIcon
          opacity="1"
          width="20"
          height="20"
          style={{ position: 'absolute', zIndex: 1 }}
        />
        <ThumbnailImage url={thumbnailUrl} />
      </Overlay>
    );
  }
}

export default Thumbnail;

const Overlay = styled.div`
  width: ${props => props.width};
  height: ${props => props.height};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${greyscale09};

  border-radius: 5px;

  border: ${props => (props.playing ? `3px solid ${aqua10}` : '')};
  box-shadow: ${props =>
    props.playing ? '2px 2px 10px 2px rgba(0, 0, 0, 0.15)' : ''};

  cursor: pointer;
`;

const ThumbnailImage = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  overflow: hidden;

  background-image: url(${props => props.url});
  background-size: cover;
  background-position: center;

  opacity: 0.5;
`;
