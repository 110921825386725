import React from 'react';
import styled from 'styled-components';
import firebase from 'firebase/app';

// redux imports
import { connect } from 'react-redux';

// resources imports
import {
  aqua10,
  greyscale07,
  greyscale10,
  h2,
  normal,
  white
} from '../../resources/consts/styleGuide';
import closeIcon from '../../resources/images/icons/close_icon.svg';

// component imports
import DatePicker from '../global/DatePicker/DatePicker';

class GetInTouchModal extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { selectedDate: null, message: '', isSubmitted: false };
  }

  componentDidMount() {
    const { userId, hostId } = this.props;

    window.scrollTo(0, 0);

    firebase.analytics().logEvent('meeting_booking_intend', {
      user_id: userId,
      host_id: hostId
    });
  }

  setStartDate = date => {
    this.setState({ selectedDate: date });
  };

  handleSubmit = event => {
    event.preventDefault();
    const { hostName, hostId, user } = this.props;
    const { selectedDate, message } = this.state;

    const body = `
    Host Name = ${hostName},
    Host Id = ${hostId},
    User email = ${user.email},
    Planned Date = ${selectedDate},
    Message = ${message}
    `;

    window.Email.send({
      Host: 'smtp.elasticemail.com',
      Username: 'francis@livescale.tv',
      Password: 'D7F400BA420485D6BAD015245E0C3389E57C',
      To: 'host@livescale.tv',
      From: user.email,
      Subject: 'Get in touch',
      Body: body
    }).then(send => {
      if (send) {
        firebase
          .analytics()
          .logEvent('meeting_booked', { user_id: user.id, host_id: hostId });
      }
    });

    this.setState({ isSubmitted: true });
  };

  handleChange = event => {
    this.setState({ [event.target.name]: event.target.value });
  };

  render() {
    const { onClose, hostName } = this.props;
    const { selectedDate, message, isSubmitted } = this.state;

    return (
      <>
        <Scrim onClick={onClose} />
        {isSubmitted ? (
          <ThankYouContainer>
            <SubTitle>
              Thanks for your interest! You will hear from us shortly.
            </SubTitle>
            <Button onClick={onClose} align="center">
              Close
            </Button>
          </ThankYouContainer>
        ) : (
          <Container>
            <CloseIcon src={closeIcon} onClick={onClose} />
            <Title>{`Get In Touch (${hostName})`}</Title>
            <Section>
              <SubTitle>Planned Event Date</SubTitle>
              <DatePicker
                selectedDate={selectedDate}
                onChange={date => this.setStartDate(date)}
              />
            </Section>
            <Section style={{ width: '100%' }}>
              <SubTitle>Message to the host</SubTitle>
              <FormText
                type="text"
                name="message"
                placeholder="Message to the host..."
                value={message}
                onChange={this.handleChange}
              />
            </Section>
            <Button onClick={this.handleSubmit} align="flex-end">
              Get In Touch
            </Button>
          </Container>
        )}
      </>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  user: state.user
});

export default connect(
  mapStateToProps,
  null
)(GetInTouchModal);

const Scrim = styled.div`
  background-color: ${greyscale10};
  height: 100%;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 999998;

  opacity: 50%;
`;

const Container = styled.div`
  width: 680px;
  min-height: 430px;
  background-color: ${white};

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  row-gap: 30px;

  box-sizing: border-box;
  padding: 30px 40px;

  overflow: hidden;
  z-index: 999999;

  :focus {
    outline: none;
  }
`;

const ThankYouContainer = styled.div`
  width: 680px;
  min-height: 430px;
  background-color: ${white};

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
  border-radius: 5px;

  position: absolute;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  row-gap: 30px;

  box-sizing: border-box;
  padding: 30px 40px;

  overflow: hidden;
  z-index: 999999;

  :focus {
    outline: none;
  }
`;

const CloseIcon = styled.img`
  position: absolute;
  top: 20px;
  right: 20px;

  cursor: pointer;
`;

const Title = styled(h2)`
  color: ${greyscale07};
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 27px;
  margin: 0px;
`;

const Section = styled.div``;

const SubTitle = styled(normal)`
  color: ${greyscale07};
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  margin: 0px 0px 5px 0px;
`;

const FormText = styled.textarea`
  width: 100%;
  min-width: 600px;
  max-width: 600px;
  height: 160px;
  min-height: 120px;
  max-height: 260px;

  padding: 10px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 19px;
  letter-spacing: 0.05em;

  background: #ffffff;

  border: 1px solid #cccccc;
  box-sizing: border-box;
  border-radius: 4px;
`;

const Button = styled.button`
  align-self: ${props => props.align};

  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8px 12px;

  border: none;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  text-align: center;
  color: ${white};

  width: 100px;
  height: 32px;

  background: ${aqua10};
  border-radius: 4px;

  cursor: pointer;

  :hover {
    opacity: 0.7;
  }
`;
