import React from 'react';
import styled from 'styled-components';
import { Link, Redirect } from 'react-router-dom';

// redux imports
import { connect } from 'react-redux';
import { GetHost } from '../../redux/actions/host';

// resources imports
import { greyscale05 } from '../../resources/consts/styleGuide';
import { LOGIN, HOSTS } from '../../resources/consts/routes';

// components imports
import HostCard from '../../components/host/HostCard';
import CoreMetrics from '../../components/host/profile/CoreMetrics';
import SampleVideos from '../../components/host/profile/SampleVideos';
import Description from '../../components/host/profile/Description';
import TrustedBy from '../../components/host/profile/TrustedBy';
import Demographics from '../../components/host/profile/Demographics';
import Audiences from '../../components/host/profile/Audiences';
import Socials from '../../components/host/Socials';
import HostPricing from '../../components/host/Pricing';
import GetInTouch from '../../components/host/GetInTouch';

class Hosts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hostId: props.match.params.id,
      hostProfile: {
        name: '',
        description: '',
        socials: [],
        stats: { events: 0, trusted_by: 0, viewers: 0, stream_time: 0 },
        videos: null,
        trusted_by: [],
        pricing: []
      }
    };
  }

  async componentDidMount() {
    const { getHost } = this.props;
    const { hostId } = this.state;

    const hostProfile = await getHost(hostId);

    this.setState({ hostProfile });

    // eslint-disable-next-line no-undef
    document.title = `Marketplace - ${hostProfile.name}`;
  }

  componentDidUpdate(prevProps) {
    const { host } = this.props;
    if (prevProps.host.trusted_by !== host.trusted_by) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({ hostProfile: host });
    }
  }

  render() {
    const { auth, user } = this.props;
    const { hostProfile } = this.state;

    if (!auth.uid) {
      return <Redirect to={LOGIN} />;
    }

    return (
      <Container>
        <SideContainer>
          <RedirectLink to={HOSTS}>{'< Back to Catalog'}</RedirectLink>
          <HostCard
            profile={hostProfile}
            isFavorite={
              user &&
              user.favorites &&
              user.favorites.some(favorite => favorite.id === hostProfile.id)
            }
            clickable={false}
            withPlayer={false}
          />
          {hostProfile.socials.length > 0 && (
            <Socials socials={hostProfile.socials} />
          )}
          <HostPricing pricing={hostProfile.pricing} />
          <GetInTouch
            userId={user.id}
            hostName={hostProfile.name}
            hostId={hostProfile.id}
          />
        </SideContainer>
        <MainContainer>
          <CoreMetrics
            events={hostProfile.stats?.events}
            trustedBy={hostProfile.stats?.trusted_by}
            viewedBy={hostProfile.stats?.viewers}
            streamTime={hostProfile.stats?.stream_time}
            tags={hostProfile.tags}
          />
          {hostProfile.videos && (
            <SampleVideos
              videos={hostProfile.videos}
              image={hostProfile.profile_image}
            />
          )}
          <Description
            name={hostProfile.name}
            description={hostProfile.description}
          />
          <Demographics demographics={hostProfile.demographics} />
          <TrustedBy brands={hostProfile.trusted_by} />
          {hostProfile.socials.length > 0 && (
            <Audiences socials={hostProfile.socials} />
          )}
        </MainContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  user: state.user,
  host: state.host
});

const mapDispatchToProps = dispatch => ({
  getHost: hostId => dispatch(GetHost(hostId))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hosts);

const Container = styled.div`
  margin: 30px 42px;
  display: flex;
  justify-content: center;

  column-gap: 30px;
`;

const RedirectLink = styled(Link)`
  color: ${greyscale05};
  cursor: pointer;
  text-decoration-line: underline;
  font-size: 12px;
`;

const SideContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  row-gap: 30px;
`;

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  margin-top: 45px;

  row-gap: 30px;
`;
