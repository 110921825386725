const initState = {};

const hostReducer = (state = initState, action) => {
  switch (action.type) {
    case 'GET_HOST': {
      return { ...state, ...action.action };
    }
    default:
      return state;
  }
};

export default hostReducer;
