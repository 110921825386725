import React from 'react';
import styled from 'styled-components';
import ReactPlayer from 'react-player/lazy';
import firebase from 'firebase/app';

// resources imports
import PlayIcon from '../../../resources/images/icons/play_icon';
import { greyscale09 } from '../../../resources/consts/styleGuide';
import { isYoutubeUrl } from '../../../utilities/detector';

class SampleVideos extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { playing: false };
  }

  render() {
    const { url, width, height, fallbackImage } = this.props;
    const { playing } = this.state;

    const isYoutube = isYoutubeUrl(url);

    return (
      <Overlay width={width} height={height}>
        <Player
          url={url}
          light={isYoutube ? true : fallbackImage}
          playIcon={
            <PlayIcon
              opacity="1"
              width="80"
              height="80"
              style={{ position: 'absolute', zIndex: 1 }}
            />
          }
          playsinline
          playing={playing}
          onStart={() => {
            firebase.analytics().logEvent('video_played_in_profile');
          }}
          onClick={() => {
            this.setState(prevState => ({
              playing: !prevState.playing
            }));
          }}
        />
      </Overlay>
    );
  }
}

export default SampleVideos;

const Overlay = styled.div`
  width: auto;
  height: auto;
  max-width: ${props => props.width};
  max-height: ${props => props.height};

  display: flex;
  align-items: center;
  justify-content: center;

  background-color: ${greyscale09};

  border-radius: 5px;

  cursor: pointer;
`;

const Player = styled(ReactPlayer)`
  overflow: hidden;
`;
