/* eslint-disable no-restricted-syntax */
/* eslint-disable no-await-in-loop */
export const GetHost = hostId => async (
  dispatch,
  getState,
  { getFirebase, getFirestore }
) => {
  const firebase = getFirebase();
  const firestore = getFirestore();

  if (hostId) {
    const userSnapshot = await firestore
      .collection('users')
      .doc(hostId)
      .get();

    const hostData = userSnapshot.data();

    const brandsSnapshot = await firestore
      .collection('users')
      .doc(hostId)
      .collection('trusted_by')
      .get();

    const brands = [];
    brandsSnapshot.forEach(async doc => {
      const brandData = doc.data();
      await brandData.reference.get().then(brandDoc => {
        brands.push(brandDoc.data());
      });

      if (brands.length === brandsSnapshot.size) {
        dispatch({
          type: 'GET_HOST',
          action: { ...hostData, trusted_by: brands }
        });
      }
    });

    firebase.analytics().logEvent('host_profile_visit');

    return { ...hostData, trusted_by: [] };
  }

  return {};
};

export const AddHost = profile => async (
  dispatch,
  getState,
  { getFirestore }
) => {
  const firestore = getFirestore();

  const host = await firestore.collection('users').doc(profile.id);

  host.set({ id: host.id, ...profile });

  return host;
};

export const AddHostPicture = (hostId, file) => async (
  dispatch,
  getState,
  { getFirebase }
) => {
  const firebase = getFirebase();
  const storage = firebase.storage();

  try {
    await storage
      .ref('hosts')
      .child(`${hostId}/${file.name}`)
      .put(file);

    const url = await storage
      .ref(`hosts/${hostId}/${file.name}`)
      .getDownloadURL();

    return { data: url, error: false };
  } catch (error) {
    return { data: error, error: true };
  }
};
