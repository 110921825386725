import React, { useState } from 'react';

// utilities import
import { generateId } from '../../../utilities/generator';

const Tags = ({ onChange, nextTags }) => {
  const [tags, setTag] = useState(nextTags);

  return (
    <div>
      <button
        style={{ marginBottom: '20px' }}
        type="button"
        onClick={() => {
          setTag(currentTags => [
            ...currentTags,
            {
              id: generateId(),
              text: '',
              type: ''
            }
          ]);
        }}
      >
        Add new Tag
      </button>
      {tags.map(tag => (
        <div style={{ marginBottom: '20px' }} key={tag.id}>
          <label htmlFor="text" style={{ marginRight: '20px' }}>
            Tag Text:
            <select
              id="text"
              name="text"
              onChange={e => {
                const text = e.target.value;
                setTag(currentTags =>
                  currentTags.map(currentTag =>
                    currentTag.id === tag.id
                      ? {
                          ...currentTag,
                          text
                        }
                      : currentTag
                  )
                );
              }}
              value={tag.text}
            >
              <option value="">Choose a Value</option>
              <option value="Beauty">Beauty</option>
              <option value="Skincare">Skincare</option>
              <option value="Health & Wellness">Health & Wellness</option>
              <option value="Home & Decor">Home & Decor</option>
              <option value="Food & Beverage">Food & Beverage</option>
              <option value="Fashion & Lifestyle">Fashion & Lifestyle</option>
              <option value="Activewear">Activewear</option>
              <option value="Family">Family</option>
              <option value="">====================</option>
              <option value="Certified">Certified</option>
              <option value="KOS">KOS</option>
              <option value="KOL">KOL</option>
              <option value="KOC">KOC</option>
            </select>
          </label>
          <label htmlFor="type" style={{ marginRight: '20px' }}>
            Tag Type:
            <select
              id="type"
              name="type"
              onChange={e => {
                const type = e.target.value;
                setTag(currentTags =>
                  currentTags.map(currentTag =>
                    currentTag.id === tag.id
                      ? {
                          ...currentTag,
                          type
                        }
                      : currentTag
                  )
                );
              }}
              value={tag.type}
            >
              <option value="">Choose a Value</option>
              <option value="industry">Industry</option>
              <option value="performance">
                Performance (Certified, KOL, KOS, etc.)
              </option>
            </select>
          </label>
          <button
            style={{ marginRight: '20px' }}
            type="button"
            onClick={() => {
              onChange(tags);
            }}
          >
            Update Tag
          </button>
          <button
            type="button"
            onClick={() => {
              setTag(currentTags =>
                currentTags.filter(currentTag => currentTag.id !== tag.id)
              );
            }}
          >
            Remove Tag
          </button>
        </div>
      ))}
    </div>
  );
};

export default Tags;
