import React from 'react';
import styled from 'styled-components';

// redux imports
import { connect } from 'react-redux';

// resources imports
import { normal, greyscale05, white } from '../../resources/consts/styleGuide';
import livescaleLogo from '../../resources/images/logo/livescale_with_name_grey.svg';

class Footer extends React.PureComponent {
  render() {
    const { auth } = this.props;

    if (auth.isEmpty) {
      return <></>;
    }

    return (
      <Container>
        <LogoContainer>
          <Logo src={livescaleLogo} />
          <Bar />
          <LogoTextContainer>
            <LogoText>Stand out</LogoText>
            <LogoText>Sell Live</LogoText>
          </LogoTextContainer>
        </LogoContainer>
        <SubContainer>
          <Link
            href="https://www.livescale.tv/terms-and-conditions/"
            target="_blank"
          >
            Terms & Conditions
          </Link>
          <Link href="https://www.livescale.tv/privacy-policy/" target="_blank">
            Privacy Policies
          </Link>
        </SubContainer>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth
});

export default connect(
  mapStateToProps,
  null
)(Footer);

const Container = styled.div`
  width: 100%;
  height: 160px;
  background-color: ${white};

  padding: 0px 50px 0px 70px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  box-sizing: border-box;

  box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.15);
`;

const LogoContainer = styled.div`
  width: 242px;
  height: 26px;

  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Logo = styled.img`
  width: 172px;
  height: 24px;
`;

const Bar = styled.div`
  width: 1px;
  height: 26px;

  background: ${greyscale05};
`;

const LogoTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`;

const LogoText = styled(normal)`
  color: ${greyscale05};
  margin: 0px;
  font-size: 10px;
  font-style: normal;
  font-weight: 600;
  line-height: 11px;
  letter-spacing: 0em;
`;

const SubContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

const Link = styled.a`
  color: ${greyscale05};
  text-decoration: underline;

  margin: 20px;

  font-family: 'Open Sans', sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
`;
