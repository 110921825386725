import React from 'react';
import styled from 'styled-components';
// import { findFlagUrlByIso2Code } from 'country-flags-svg';
import countries from 'i18n-iso-countries';
import { Redirect } from 'react-router-dom';

// redux imports
import { connect } from 'react-redux';
import { GetUser } from '../../redux/actions/user';
import { GetHosts } from '../../redux/actions/hosts';

// resources imports
// import { normal, greyscale05 } from '../../resources/consts/styleGuide';
import { LOGIN, HOST_PROFILE } from '../../resources/consts/routes';

// components imports
import HostCard from '../../components/host/HostCard';

countries.registerLocale(require('i18n-iso-countries/langs/en'));

class Hosts extends React.Component {
  async componentDidMount() {
    const { auth, getUser } = this.props;

    await getUser(auth.uid);
    // eslint-disable-next-line no-undef
    document.title = `Marketplace - Hosts`;
  }

  componentDidUpdate(prevProps) {
    const {
      getHosts,
      user: { favorites }
    } = this.props;
    if (prevProps.user.favorites !== favorites) {
      getHosts();
    }
  }

  render() {
    const { history, auth, user, hosts } = this.props;

    // const flagUrl = findFlagUrlByIso2Code('US');
    // const countryName = countries.getName('US', 'en', {
    //   select: 'alias'
    // });

    if (!auth.uid) {
      return <Redirect to={LOGIN} />;
    }

    return (
      <Container>
        {/* <InfoBar>
          <SubInfoBar>
            <InfoBarText>100+ Host(s) Found</InfoBarText>
            <InfoBarText>Sort By</InfoBarText>
            <InfoBarText underline>Relevance</InfoBarText>
          </SubInfoBar>
          <SubInfoBar>
            <InfoBarText>Displayed Currency</InfoBarText>
            <CountryIcon src={flagUrl} />
            <InfoBarText underline>{`${countryName} - $USD`}</InfoBarText>
          </SubInfoBar>
        </InfoBar> */}
        <HostsSection>
          {hosts.profiles &&
            hosts.profiles.map(profile => (
              <HostCard
                key={profile.id}
                profile={profile}
                isFavorite={
                  user &&
                  user.favorites &&
                  user.favorites.some(favorite => favorite.id === profile.id)
                }
                clickable
                withPlayer
                onClick={async () => {
                  history.push(`${HOST_PROFILE}/${profile.id}`);
                }}
              />
            ))}
        </HostsSection>
      </Container>
    );
  }
}

const mapStateToProps = state => ({
  auth: state.firebase.auth,
  user: state.user,
  hosts: state.hosts
});

const mapDispatchToProps = dispatch => ({
  getUser: userId => dispatch(GetUser(userId)),
  getHosts: () => dispatch(GetHosts())
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Hosts);

const Container = styled.div`
  display: flex;
  justify-content: center;
`;

// const InfoBar = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;

//   margin: 32px 50px 32px 50px;
// `;

// const SubInfoBar = styled.div`
//   display: flex;
//   flex-direction: row;
//   justify-content: space-between;
// `;

// const InfoBarText = styled(normal)`
//   color: ${greyscale05};
//   opacity: 0.5;
//   margin-right: 20px;

//   text-decoration: ${props => (props.underline ? 'underline' : '')};
// `;

// const CountryIcon = styled.img`
//   width: 16px;
//   height: 12px;

//   margin: 2px 5px 0px 0px;
// `;

const HostsSection = styled.div`
  width: 100%;

  column-gap: 40px;
  row-gap: 40px;

  display: flex;
  flex-direction: grid;
  flex-wrap: wrap;
  justify-content: center;

  margin: 40px 120px;
`;
