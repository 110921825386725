import { findFlagUrlByIso2Code } from 'country-flags-svg';
import countries from 'i18n-iso-countries';

countries.registerLocale(require('i18n-iso-countries/langs/en'));

export function getCountryInfo(countryISO2Code) {
  let flagUrl;
  let name;

  if (countryISO2Code) {
    flagUrl = findFlagUrlByIso2Code(countryISO2Code);
    name = countries.getName(countryISO2Code, 'en', {
      select: 'official'
    });
  }

  return { flagUrl, name };
}
