import React from 'react';

const HeartIcon = ({
  opacity = '1',
  width = '12',
  height = '12',
  color = '#ffffff'
}) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 12 12"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      opacity={opacity}
      d="M10.8352 0.820517C9.55089 -0.430353 7.64077 -0.205357 6.46188 1.1848L6.00017 1.72854L5.53846 1.1848C4.36192 -0.205357 2.44946 -0.430353 1.16511 0.820517C-0.306738 2.25621 -0.38408 4.83294 0.933082 6.38917L5.46815 11.7409C5.76111 12.0864 6.23689 12.0864 6.52985 11.7409L11.0649 6.38917C12.3844 4.83294 12.3071 2.25621 10.8352 0.820517V0.820517Z"
      fill={color}
    />
  </svg>
);

export default HeartIcon;
